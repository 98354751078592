/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-08-30 22:44:31
 * @FilePath: \web-app\src\main.js
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import wx from 'weixin-js-sdk';

import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

import vueHashCalendar from 'vue-hash-calendar'
import 'vue-hash-calendar/lib/vue-hash-calendar.css'
Vue.use(vueHashCalendar)

import tools from '@/utils/tools';
Vue.prototype.tools = tools;

// 引入echarts
import * as echarts from 'echarts'
// import echarts from 'echarts'
Vue.prototype.$echarts = echarts


import 'amfe-flexible';
import 'amfe-flexible/index.js';



router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
});

import {i18n, vantLocales} from './assets/language/index';


Vue.prototype.$wx = wx;
vantLocales(i18n.locale)

Vue.config.productionTip = false;

new Vue({
  router,
  i18n,
  store,
  render: (h) => h(App),
}).$mount("#app");
