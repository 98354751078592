import { render, staticRenderFns } from "./prizeList.vue?vue&type=template&id=6f97690b&scoped=true"
import script from "./prizeList.vue?vue&type=script&lang=js"
export * from "./prizeList.vue?vue&type=script&lang=js"
import style0 from "./prizeList.vue?vue&type=style&index=0&id=6f97690b&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f97690b",
  null
  
)

export default component.exports