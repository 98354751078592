<template>
    <div class="transactionAmount">
      <div class="hear">
        <h4>营销数据</h4>
      </div>
      <div class="list-box">
        <div class="lists">
          <!-- <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="lists"
          > -->
          <div class="item-box">
            <div class="item">
              <ul>
                <li></li>
                <li><img src="@/assets/images/dy0.png"></li>
                <li><img src="@/assets/images/wx0.png"></li>
                <li><img src="@/assets/images/ks.png"></li>
                <li><img src="@/assets/images/xhs.png"></li>
                <li><img src="@/assets/images/sph.png"></li>


                <li>播放量</li>
                <li>{{viewNum}}</li>
                <li>---</li>
                <li>---</li>
                <li>---</li>
                <li>---</li>

                <li>点赞量</li>
                <li>{{likeNum}}</li>
                <li>---</li>
                <li>---</li>
                <li>---</li>
                <li>---</li>

                <li>转发量</li>
                <li>{{commentNum}}</li>
                <li>{{totalShare}}<li>
                <li>---</li>
                <li>---</li>
                <li>---</li>
              </ul>
            </div>
          </div>
          <!-- </van-list> -->
        </div>
      </div>
      <div class="home" @click="goHome"><img src="@/assets/images/return.png"/></div>
    </div>
  </template>
  
  <script>
import API from "@/api/api_shop.js";
export default {
  name: "transactionAmount",
  data() {
    return {
      tabList: [
        {
          name: "今日",
          id: "1",
        },
        {
          name: "近7天",
          id: "2",
        },
        {
          name: "近30天",
          id: "3",
        },
        // {
        //   name: "自定义",
        //   id: "4",
        // },
      ],

      shopList: [
  
      ],
      totalShare:0,
      commentNum:0,
      viewNum:0,
      likeNum:0,
      current: 0,
      lists: [1, 1, 1, 1, 1, 1, 1],
      loading: false,
      finished: false,
      searchVal: "",
      m0: 52006,
      m1: 49864,
      userType: "",
    };
  },
  created() {
    this.getMarketData();
  },
  methods: {

    goHome(){
      location.href="/shopCenter/index";
    },
    tabClick(i) {
      this.current = i;
      if (this.current == 0) {
        this.m0 = 52006;

        this.shopList[0].deal = 49885;
        this.shopList[0].income = 99.77;

        this.shopList[1].deal = 2121;
        this.shopList[1].income = 4.24;
      }
      //alert(this.current)
      if (this.current == 1) {
        this.m0 = 313832;
        this.shopList[0].deal = 289140;
        this.shopList[0].income = 578.28;

        this.shopList[1].deal = 24692;
        this.shopList[1].income = 49.38;
      }
      if (this.current == 2) {
        this.m0 = 623004;

        this.shopList[0].deal = 510003;
        this.shopList[0].income = 1020;

        this.shopList[1].deal = 113001;
        this.shopList[1].income = 226;
      }
    },
    getMarketData() {
      
        API.getMarketData().then((res) => {
            console.log(res.total.totalShare);
           this.totalShare=res.total.totalShare==null?0:res.total.totalShare;
           this.commentNum=res.commentNum==null?0:res.commentNum;
           this.viewNum=res.viewNum==null?0:res.viewNum;
           this.likeNum=res.likeNum==null?0:res.likeNum;
        });
      
    },
    onLoad() {},
  },
};
</script>
  
  <style  lang="scss" scoped>
  ol li:not(.ql-direction-rtl), ul li:not(.ql-direction-rtl){
    padding-left: 0px;
  }
  ul>li:before {
    content: "";
    }
  .home{
  position:fixed;
  top:3%;
  left: 5%;
  img{
    width: 20%;
    height: 20%;
  }
}
.transactionAmount {
  background: #fff;
  .hear {
    width: 100%;
    height: 150px;
    background-color: #ffcb51;
    background-image: linear-gradient(62deg, #ffcb51 0%, #f7d568 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0px;
    h4 {
      font-size: 32px;
    }
    .tab {
      .item {
        font-size: 24px;
        padding: 6px 10px;
        box-sizing: border-box;
        color: #666;
        background: #fff;
        margin-right: 16px;
        border-radius: 8px;
        &.active {
          color: #ff6619;
        }
      }
    }
    .money {
      text-align: center;
      .icon {
        font-size: 30px;
        color: #666;
      }
      .num {
        font-size: 50px;
        color: #000;
      }
      p {
        font-size: 24px;
        color: #000;
      }
    }
  }
  .list-box {
    padding: 20px;
    box-sizing: border-box;
    h4 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 30px;
    }
    .lists {
      width: 100%;
      //   display: flex;
      //   justify-content: space-between;
      //   align-content: center;
      .item-box {
        width: 100%;
        background: #eee;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        .name {
          font-size: 28px;
          color: #000;
          font-weight: 700;
          margin-bottom: 10px;
          float: left;
        }
        .ranking {
          font-size: 28px;
          color: rgb(56, 56, 56);
          font-weight: 700;
          margin-bottom: 10px;
          float: right;
          span {
            color: #ff6619;
            font-size: 30px;
          }
        }
        .item {
          width: 100%;
          font-size: 26px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          .red {
            color: red;
          }
          .fontw700 {
            font-weight: 700;
          }
          p {
            margin-bottom: 10px;
          }
          ul {
            width: 100%;
          }
          ul li {
            float: left;
            width: 16%;
            height: 120px;
            text-align: center;
            padding-top: 40px;
          }
          li img {
            width: 60%;
          }
        }
      }
    }
  }
}
</style>