<template>
    <div class="transactionAmount">
      <div class="hear">
        <h4>通知公告</h4>
      </div>
      <div class="list-box">
        <div class="lists">
            <div class="tit">{{title}}</div>
            <div class="desc" v-html="desc">
              
            </div>
          <!-- </van-list> -->
        </div>
      </div>
      <div class="home" @click="goHome"><img src="@/assets/images/return.png"/></div>
    </div>
  </template>
  
  <script>
import API from "@/api/api_shop.js";
export default {
  name: "transactionAmount",
  data() {
    return {
      id:0,
      userType: "",
      title:"",
      desc:""
    };
  },
  created() {
    this.userType = localStorage.getItem("userType");
    this.id = this.$route.query.id;

    this.getNoteDetail(this.id);
  },
  methods: {

    goHome(){
      location.href="/shopCenter/index";
    },
    getNoteDetail(id){
        API.getNoteDetail(id).then((res) => {
          console.log(res);
          this.title=res.data.noticeTitle
          this.desc=res.data.noticeContent
        });
    },
    
    onLoad() {},
  },
};
</script>
  
  <style  lang="scss" scoped>
  .tit{font-size:32px ; font-weight: bold; width: 100%;height: 60px; text-align: center;}
  .desc{font-size: 28px; line-height: 50px; margin-top: 50px;}
  .home{
  position:fixed;
  top:3%;
  left: 5%;
  img{
    width: 20%;
    height: 20%;
  }
}
.transactionAmount {
  background: #fff;
  .hear {
    width: 100%;
    height: 150px;
    background-color: #ffcb51;
    background-image: linear-gradient(62deg, #ffcb51 0%, #f7d568 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0px;
    h4 {
      font-size: 32px;
    }
    .tab {
      .item {
        font-size: 24px;
        padding: 6px 10px;
        box-sizing: border-box;
        color: #666;
        background: #fff;
        margin-right: 16px;
        border-radius: 8px;
        &.active {
          color: #ff6619;
        }
      }
    }
    .money {
      text-align: center;
      .icon {
        font-size: 30px;
        color: #666;
      }
      .num {
        font-size: 50px;
        color: #000;
      }
      p {
        font-size: 24px;
        color: #000;
      }
    }
  }
  .list-box {
    padding: 20px;
    box-sizing: border-box;
    h4 {
      font-size: 36px;
      text-align: center;
      margin-bottom: 30px;
    }
    .lists {
      width: 100%;
      //   display: flex;
      //   justify-content: space-between;
      //   align-content: center;
      .item-box {
        width: 100%;
        background: #eee;
        border-radius: 10px;
        padding: 20px;
        margin-bottom: 20px;
        box-sizing: border-box;
        .name {
          font-size: 28px;
          color: #000;
          font-weight: 700;
          margin-bottom: 10px;
          float: left;
        }
        .ranking {
          font-size: 28px;
          color: rgb(56, 56, 56);
          font-weight: 700;
          margin-bottom: 10px;
          float: right;
          span {
            color: #ff6619;
            font-size: 30px;
          }
        }
        .item {
          width: 100%;
          font-size: 26px;
          display: flex;
          justify-content: space-between;
          align-content: center;
          .red {
            color: red;
          }
          .fontw700 {
            font-weight: 700;
          }
          p {
            margin-bottom: 10px;
          }
          ul {
            width: 100%;
          }
          ul li {
            float: left;
            width: 16%;
            height: 120px;
            text-align: center;
            padding-top: 40px;
          }
          li img {
            width: 60%;
          }
        }
      }
    }
  }
}
</style>