/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:47:54
 * @LastEditTime: 2023-05-04 21:58:56
 * @FilePath: \web-app\src\api\index.js
 */

import axios from "axios";
import store from "@/store"
import { Toast } from 'vant';


axios.defaults.withCredentials = true; //跨域带cookies
axios.defaults.timeout = 50000;  //设置超时时间

// axios.defaults.baseURL = process.env.NODE_ENV === 'production' ? '/api' : '/api'

axios.defaults.baseURL = 'https://bill.ld-top.com/'; //这是调用数据接口

//添加一个请求拦截器
axios.interceptors.request.use(
  (config) => {
    console.log(config);
    const token = store.state.token || localStorage.getItem("access-token") || localStorage.getItem("access-token-shop"); //获取存储在本地的token
   
    if (config.url == "/file/ossUpload") {
      config.timeout=50000;
      config.headers = {
        'Content-Type': 'multipart/form-data' //设置跨域头部,虽然很多浏览器默认都是使用json传数据，但咱要考虑IE浏览器。
      };
      if (token) {
        config.headers['Authorization'] = token; //携带权限参数
      }
      config.headers['fenp'] = '1'; //携带权限参数
      return config;

    } else {
      config.data = JSON.stringify(config.data);
      //config.data = JSON.stringify(config.data);
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8'//设置跨域头部,虽然很多浏览器默认都是使用json传数据，但咱要考虑IE浏览器。
      };
      if (token) {
        config.headers['token'] = token;
        //config.headers['Authorization'] = token; //携带权限参数
        console.log(config.baseURL);
        if(config.baseURL!="https://bill.ld-top.com/"){
           config.headers["Authorization"]=token;
        }
        if(config.url=="/captchaImage" || config.url=="/login"){
         config.headers["Authorization"]=null;
        }
        
        
       }
        //config.headers['fenp'] = '1'; //携带权限参数 
        config.headers['h-language'] = localStorage.getItem('lang') || 'zh_CN'; //携带权限参数 
       return config;
    }
    //config.headers["token"] = token;
    //config.headers["Authorization"]=token;
    
  },
  (error) => {
    return Promise.reject(error);
  }
);
// 添加一个响应拦截器
axios.interceptors.response.use(response => {
  
  if (response.headers['access-token'] && response.headers['access-token'] !== 'invalid_token') {
      localStorage.setItem('access-token',response.headers['access-token']);
      store.commit('updataToken', response.headers['access-token']);
    }
    
    
    if (response.data && response.data.code) {
      if (parseInt(response.data.code) === 401) {
        //未登录
        Toast('登录失效，请重新登录！');
        setTimeout(() => {
          window.location.href="/shopCenter/login";
        }, 2000);
      }else if(parseInt(response.data.code) != 1 && parseInt(response.data.code) != 200){
        Toast(response.data.msg);
      }
    }
    return response;
  },
  
  function (error) {
    // Do something with response error
    // Toast('服务器异常')
    return Promise.reject(error);
  }
); 


//通用方法
export const POST = (url, params,BASE_URL) => {
  if(BASE_URL){
    axios.defaults.baseURL = BASE_URL;
  }else{
    axios.defaults.baseURL = 'https://bill.ld-top.com/';
  }
  return axios.post(`${url}`, params)
    .then((res) => res.data);
};

export const GET = (url, params,BASE_URL) => {
  if(BASE_URL){
    axios.defaults.baseURL = BASE_URL; 
  }else{
    axios.defaults.baseURL = 'https://bill.ld-top.com/';
    //config.headers["Authorization"]=null; 
  }
  return axios.get(`${url}`, {
      params: params
    })
    .then((res) => res.data);
};

export const PUT = (url, params,BASE_URL) => {
  if(BASE_URL){
    axios.defaults.baseURL = BASE_URL; 
  }else{
    axios.defaults.baseURL = 'https://bill.ld-top.com/';
    //config.headers["Authorization"]=null; 
  }
  return axios.put(`${url}`, params)
    .then((res) => res.data);
};


export const DELETE = (url, params) => {
  return axios.delete(`${url}`, {
    params: params
  }).then(res => res.data)
}

export const PATCH = (url, params) => {
  return axios.patch(`${url}`, params).then((res) => res.data);
};
