<template>
    <div class="service">
      <div class="hear">
        <!-- <div class="title">
          <h4>我买单服务商中心</h4>
          <p class="tip">感谢对我买单的每一份付出与努力</p>
        </div> -->
        <div class="service-info">
          <div class="name">{{userInfo.nickName}}</div>
          <div class="tip">
            {{dept.deptName=="业务员"?"业务专员":dept.deptName}}
            <!-- <van-rate
              v-model="rateNum"
              :size="14"
              icon="like"
              void-icon="like-o"
            /> -->
          </div>
        </div>
        <div class="overview">
          <div class="item" @click="goOrderData">
            <div class="tit">交易金额</div>
            <div class="money">￥{{total/1000.00}}</div>
            <div class="check">查看详细>></div>
          </div>
          <div class="item">
            <div class="tit">消费收益</div>
            <div class="money">￥{{bindree/1000.00}}</div>
            <div class="check">查看详细>></div>
          </div>
      
          
        </div>
      </div>
      <!-- 公告 -->
      <div class="notice">
        <van-notice-bar left-icon="volume-o" :scrollable="false" background="#fff">
          <van-swipe
            vertical
            class="notice-swipe"
            :autoplay="3000"
            :show-indicators="false"
          >
            <van-swipe-item v-for="item in noteList" :key="item.id" @click="showDetails(item.noticeId)">{{item.noticeTitle}}</van-swipe-item>
            
          </van-swipe>
        </van-notice-bar>
      </div>
      <!-- 菜单列表 -->
      <div class="menuLists">
        <div class="item" v-for="item in menuLists" :key="item.id" @click="goUrl(item.url)">
          <img :src="item.icon" alt="图标" srcset="" />
          <p>{{ item.name }}</p>
        </div>
      </div>
      
      <div
      style="
        position: fixed;
        top: 50%;
        left: 50%;
        width: 300px;
        height: 220px;
        margin-left: -150px;
        margin-top: -100px;
        background-color: #e7e3e3;
        font-size: 18px;
      "
      v-show="isshow"
    >
      <ul style="list-style:none;">
        <li style="padding-top: 20px;float:left;list-style:none;">
          <span style="float:left">原密码：</span
          ><input type="password" v-model="orgPwd" style="width: 150px;float:left" />
        </li>
        <li style="padding-top: 20px;float:left;list-style:none;">
          <span style="float:left">新密码：</span
          ><input type="password" v-model="newPwd" style="width: 150px" />
        </li>
        <li style="padding-top: 20px;float:left;list-style:none;">
          <span style="float:left">确认密码：</span
          ><input type="password" v-model="confimPwd" style="width: 130px" />
        </li>
      </ul>
      <div
        style="
          width: 30%;
          float: left;
          margin-left: 15%;
          text-align: center;
          margin-top: 20px;
          background-color: #ffcb51;
          height: 30px;
          padding-top: 5px;
        "
        @click="submitPwd"
      >
        确认修改
      </div>
      <div
        style="
          width: 30%;
          float: left;
          margin-left: 5%;
          text-align: center;
          margin-top: 20px;
          background-color: #ffcb51;
          color: #fff;
          height: 30px;
          padding-top: 5px;
        "
        @click="noChange"
      >
        取消修改
      </div>
    </div>


    </div>
    
  </template>
  
  <script>
  import API from "@/api/api_shop.js";
  import API0 from "@/api/api.js";
  export default {
    name: "service",
    data() {
      return {
        userInfo:{},
        userType:"",
        total:0,
        bindree:0,
        active:0,
        increase:0,
        noteList:[],
        dept:{},
        rateNum: 0,
        orgPwd: "",
        newPwd: "",
        confimPwd: "",
        isshow:false,
        form: {
        oldPwd: "",
        newPwd: "",
        surePwd: "",
      },
      // 表单效验规则
      rules: {
        oldPwd: [{ required: true, message: "请输入旧密码", trigger: "blur" }],
        newPwd: [{ required: true, message: "请输入新密码", trigger: "blur" }],
        surePwd: [
          { required: true, message: "请再次输入新密码", trigger: "blur" },
          //调用validateSurePwd方法，将新密码和确认密码进行效验
          { validator: this.validateSurePwd, trigger: "blur" },
        ],
      },
        menuLists: [
          {
            id: 1,
            name: "营销数据",
            icon: require("@/assets/images/sdata.png"),
            url: "/shopCenter/market/data",
          },
          {
            id: 2,
            name: "活动管理",
            icon: require("@/assets/images/satv.png"),
            url: "/shopCenter/active",
          },
          {
            id: 3,
            name: "素材管理",
            icon: require("@/assets/images/sfile.png"),
            url: "/shopCenter/file",
          },
          {
            id: 4,
            name: "优惠券管理",
            icon: require("@/assets/images/study.png"),
            url: "/shopCenter/coupon",
          },
          {
            id: 5,
            name: "扫码验劵",
            icon: require("@/assets/images/sqrcode.png"),
            url: "scan",
          },
          
          {
            id: 6,
            name: "团队管理",
            icon: require("@/assets/images/team.png"),
            url: "",
          },
          {
            id: 7,
            name: "修改密码",
            icon: require("@/assets/images/join.png"),
            url: "chanagePwd",
          },
         
         
          {
            id: 8,
            name: "反馈意见",
            icon: require("@/assets/images/icon_success.png"),
            url: "",
          },
          {
            id: 9,
            name: "退出登录",
            icon: require("@/assets/images/loginout.png"),
            url: "/shopCenter/login",
        },
        ],
      };
    },
    created() {
    this.isLogin();
    this.getUserInfo();
    this.getTotal();
    this.getWxConfig();
    localStorage.removeItem("access-token");
    //   this.userType=localStorage.getItem("userType");
    //   if(this.userType=="业务员"){
    //     this.getTradeInfoB();
    //     this.getNoticeListB();
    //   }else{
    //     this.getTradeInfo();
    this.getNoticeList();
   
    //   }
      
     
  
    },
  
    methods: {
      goOrderData(){
        location.href="/shopCenter/order";
      },
      scan() {

        this.$wx.scanQRCode({
        needResult: 1,// 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"],// 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          // 当needResult 为 1 时，扫码返回的结果
          let result = res.resultStr;
          let obj=JSON.parse(result);
          let para={
            shopId:obj.shopId,
            couponId:obj.couponId,
          };
          API.validaCoupon(para).then((res) => {
          if (res.code==200){
            alert("验券成功！");
          }else{
            alert(res.msg);
          }
        })

        },
        fail : function(res) {
          console.log('扫描失败!'+res.errMsg);
        }
      });
    },
    getWxConfig(){
      let curl = location.href.split('#')[0];
      //console.log(url);
      let param={
        url:curl,
        client:'JSAPI'
      };
      API0.wxConfig(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$wx.config({
            debug: false, // 开发阶段设为true，生产阶段设为false
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: ['scanQRCode'] // 需要使用的API列表
          });
          
        }
      });
    },

      showDetails(id){
        //console.log(id);
        location.href="/shopCenter/noteDetails?id="+id;
      },
      getTotal(){
        API.getTotal().then((res) => {
        if (res.code==200){
             console.log(res);
            this.total=res.total==null?0:res.total.totalFee;
            this.bindree=res.bindree==null?0:res.bindree;
         }

        })
      },
      getUserInfo(){
        API.getUserInfo().then((res) => {
          if (res.code==200){
           
            this.userInfo=res.user;
            this.userType=res.userType;
            this.dept=res.user.dept;


            localStorage.setItem("shopId",res.user.storeId);
            console.log(this.userInfo);
          }
        })
  
      },
  
      getNoticeList(){
        API.getNoticeList().then((res) => {
          if (res.code==200){
            this.noteList=res.rows;
            console.log(this.noteList);
          
          }
        })
  
      },
      getNoticeListB(){
        API.getNoticeListB().then((res) => {
          if (res.code==200){
            this.noteList=res.rows;
            console.log(this.noteList);
          
          }
        })
  
      },
      
      getTradeInfo(){
        API.getTradeInfo().then((res) => {
          if (res.code==200){
            this.total=res.total==null?0:res.total;
            this.income=res.income==null?0:res.income;
            this.active=res.active;
            this.increase=res.increase
            
          }
        })
  
      },
      getTradeInfoB(){
        API.getTradeInfoB().then((res) => {
          if (res.code==200){
            this.total=res.total==null?0:res.total;
            this.income=res.income==null?0:res.income;
            this.active=res.active;
            this.increase=res.increase
            
          }
        })
  
      },
  
      goTodayData(){
        location.href="/transactionAmount"
      },
      goTodayIncome(){
        location.href="/tradingProfit"
      },
      goNewShop(){
        location.href="/addNewShop"
      },
      goActiveShop(){
        location.href="/activeShop"
      },
      isLogin(){
      let token=localStorage.getItem("access-token-shop");
      if(token==null ||token==""){
        window.location.href ="/shopCenter/login?reurl=index";
      }
      },

      submitPwd() {
      //alert(this.newPwd);
      if (this.newPwd != this.confimPwd) {
        alert("两次输入密码不一致");
        return;
      }
      let params = "oldPassword=" + this.orgPwd + "&newPassword=" + this.newPwd;
      API.updatePwd(params).then((res) => {
        if (res.code == 200) {
          this.$toast(`修改成功`);
          location.href = "/shopCenter/index";
        }
      });
    },

    changePwd() {
      this.isshow = true;
    },
    noChange() {
      this.isshow = false;
    },
      goUrl(url){
        if (url==""){
          this.$toast(`建设中..`);
        }else if(url=="loginOut"){
          localStorage.setItem("access-token-shop","");
          location.href="/shopCenter/login?reurl=/service"
        }else if(url=="scan"){
          this.scan();
        }else if(url=="chanagePwd"){
          this.changePwd();
        }
        else{
          location.href=url
        }
        
      }
    },
  };
  </script>
  
  <style lang="scss" scoped>
  
  .service {
    .hear {
      width: 100%;
      height: 400px;
      background-color: #ffcb51;
      background-image: linear-gradient(62deg, #ffcb51 0%, #f7d568 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 30px 0px;
      .title {
        text-align: center;
        h4 {
          font-size: 42px;
          color: #443f3f;
          font-weight: 700;
        }
        .tip {
          font-size: 30px;
          color: #443f3f;
          margin-top: 20px;
        }
      }
      .service-info {
        text-align: center;
        .name {
          font-size: 42px;
          color: #333;
          font-weight: bold;
          padding-bottom: 20px;
        }
        .tip {
          font-size: 30px;
          color: #443f3f;
        }
      }
      .overview {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        width:100%;
        .item {
          width: 50%;
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 20px;
          margin-top:40px;
          .tit {
            font-size: 32px;
            font-weight: 700;
          }
          .money {
            font-size: 34px;
            font-weight: bold;
          }
          .check {
            font-size: 24px;
            color: #443f3f;
          }
        }
      }
    }
    .notice {
      margin-bottom: 20px;
      .notice-swipe {
        height: 40px;
        line-height: 40px;
      }
    }
    .menuLists {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 100px 0px;
      box-sizing: border-box;
      background: #fff;
      .item {
        width: 33.33%;
        text-align: center;
        margin-bottom: 80px;
        img {
          width: 60px;
          height: 60px;
        }
        p {
          font-size: 30px;
          color: #333;
        }
      }
    }
  }
  </style>