/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-26 23:36:49
 * @LastEditors: yhz 11231852@qq.com
 * @LastEditTime: 2023-05-11 22:40:48
 * @FilePath: \web-app\src\assets\language\language-en.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    message: {
        'noData':'No data temporarily!',
        //  客服
        'serverTip0':'Service',
        'serverTip1':'Official',
        'serverTip2':'Trend chart',
        'calendartitle':'Date selection',
        // 登录
        'pleaseLog':'please log in first!',
        'Cancel':'Cancel',
        'confirm':'Confirm',
        'goindex':'go to index',
        'Register':'Register now',
        'Account':'Account',
        'Password':'Password',
        'Welcome':'Welcome to BC international Entertainment',
        'intAccount':'Input Account',
        'inpPassword':'Input password',
        // 注册
        'placeholder1':'5-10 digits, beginning with letters',
        'placeholder2':'password cannot be the same as the user name',
        'placeholder3':'Confirm password',
        'placeholder4':'Bind trc20 wallet address',
        'placeholder5':'Verification Code',
        'loginNow':'login Now',
        'RegisterBtn':'Register',
        'RegisterTip1':'Please enter account',
        'RegisterTip2':'Login account 5-10 digits, which must start with a letter',
        'RegisterTip3':'Please enter password',
        'RegisterTip4':'Password length is greater than or equal to 6',
        'RegisterTip5':'The password cannot be the same as the user name',
        'RegisterTip6':'Please enter the password again',
        'RegisterTip7':'The two passwords are inconsistent',
        'RegisterTip8':'Please enter the wallet address',
        'RegisterTip9':'Please enter the verification code',
        // 首页
        'home':'Home',
        'lang':'LangSwitch',
        'en':'English',
        'cn':'Chinese',
        'login':'Login',
        'more':'More',
        'Notice':'Notice',
        'NotNotice':'No Notice',
        'official':'Only Official Prize Giving Address',
        'agmeItroduction':'Game introduction',
        'amountLimit':'Amount limit',
        'Odds':'Odds',
        'OnlineService':'contact online customer service!',
        'participate':'Play',
        'day':'Day',
        'week':'Week',
        'month':'Month',
        'address':'Address',
        'win':'Win',
        'process':'Process',
        'link':'Link',
        'Wallet':'Wallet',
        'WalletTip':'Digital currency [decentralized] wallet download address',
        'currency':'currency',
        'currencyTip':'Go to the exchange to buy USDT and TRX',
        'TransferBet':'Transfer Bet',
        'TransferBetTip':'After registering the platform, you can transfer the specified amount to the address specified by the platform and then participate successfully. If you win the lottery, the system will automatically settle the wallet distributed to you within 3 seconds (Note: you must use decentralized wallet to bet, and the exchange account will be directly transferred to the actual address of the player who cannot identify the blockchain transfer.)',
        'Download':'Download',
        'Platform':'Platform',
        'URL':'URL',
        'Tutorial':'Tutorial',
        'TianjiVPN':'Tianji VPN',
        'CycloneVPN':'Cyclone VPN',
        'Telegram':'Telegram',
        'Note':'Note',
        'Note1':'1.Domestic users need to download VPN software to open the exchange and download the virtual currency wallet. ',
        'Note2':'2.Following BC1 Hash Entertainment official channel and official customer service need to download Telegram software. ',
        'Note3':'3.Domestic Apple users who download wallets and exchange apps need to switch to the app store. For details, please follow the official channel of BC1 disc, which contains detailed video tutorials. Channel link:',
        'prizePoolBalance': ' Lottery Pool',
        'prizePoolBalance1': ' Capital Pool',
        'haoli1':'Haoli Grand Release',
        'haoli2':'Every night at 00:00',
        'haoli3':'Digital lottery',
        'haoli4':'form',
        'haoli5':'Great Luck Award',
        'haoli6':'Get A Break',
        'haoli7':'Fortune Luck Award',
        'haoli8':'Everything goes well',
        'haoli9':'Winning Address',
        'haoli10':"Today's No Address Winning",
        'haoli11':'Transfer Hash',
        'haoli12':'Transfer block number',
        'haoli13':'Transfer Block Hash',
        'haoli14':'Next block number',
        'haoli15':'Next Block Hash',
        'haoli16':'Qualified address',
        'haoli17':'No compliant addresses available today',
        'haoli18':'Lottery results',
        'haoli19':'Bet Block Hash',
        'haoli120':'Bet Hash',
        // 兑换
        'ExchangeT':'Exchange',
        'Exchange':'Exchange',
        'EnterQuantity':'Enter Quantity',
        'todatRate':"Today's exchange rate",
        'Scan':'Scan code to transfer and exchange',
        'Quantitylimit':'Quantity limit',
        'copy':'Copy',
        'copyAddress':'Copy',
        'or':'or',
        'Warm':'Warm reminder',
        'Warm1':'1. Open the third-party digital wallet, scan the code or copy the address for transfer. (No registration is required, no authorization is required, and all categories requiring authorization have risks. This platform uses ordinary transfer methods, please identify by yourself).',
        'Warm2':'2. The quantity of single transfer shall not exceed the limit, and it cannot be returned in seconds if it is lower than the minimum limit of single transfer. If there is any wrong transfer, please contact the customer service in time.',
        'Warm3':'3. The platform will return TRX or USDT coins of the same value to you within 3 seconds at the official hourly exchange rate.',
        'Record':'Record',
        'Order':'Order',
        'Amount':'Amount',
        'Arrival':'Arrival',
        'Time':'Exchange Time',
        'NoData':'No Data',
        'NoMore':'No More',
        'Loading':'Loading...',
        // 热门游戏
        'game':'Game',
        'heat':'Heat',
        'HotGames':'HotGames',
        'multiple':'multiple',
        'PlayNow':'PlayNow',
        'Limit':'Limit',
        'Tips':'Tips',
        'TipsC1':'1.DoNotUseTheExchangeAddress',
        'TipsC2':'2.Only TRC20 transfer',
        'HowPlay':'HowPlay',
        'jinqi':'Your recent',
        'zoushi':'Playing chart',
        'shuang':'double',
        'dan':'single',
        'da':'big',
        'xiao':'tiny',
        'ying':'won',
        'shu':'lost',
        'zhuang':'village',
        'xian':'idle',
        'he':'FLAT',
        'copySuccess':'Copy succeeded',
        'noSupport':'browser does not support automatic copying',
        "guize":'rule',
        "jieshou":'Accept only',
        'yesterday':'yesterday',
        'today':'today',


         // 活动
        'active':'Active',


         // 我的
        'me':'Me',
        'GameRecord':'Game Record',
        'P&LRecord':'P&L Record',
        'SecuritySettings':'Security Settings',
        'TeamReport':'Team Report',
        'MyReferral':'My Referral',
        'AboutUs':'About Us',
        'Help':'Help',
        'Logout':'Logout',
        'logoutTip':'Are you sure you want to log out?',
        'PlayMethod':'typeGame',
        'state':'state',
        'date':'Date',
        'serch':'serch',
        'BetAmount':'Bet amount',
        'currency':'currency',
        'ProfitLoss':'Profit and loss',
        'blockheight':'Block Height',
        'verification':'verify',
        'All':'All',
        'Winprize':'Won',
        'NoWinprize':'No Win',
        'Bet':'Bet',
        'Rebate':'Rebate',
        'extension':'Extension Rebate',
        'Sign':'Sign in',
        'editPassword':'Edit Password',
        'EditLoginPassword':'Edit login password',
        'passwordTip':'password is 6-12 digits long and can be composed of numbers and letters',
        'passwordC':'Please enter the current password',
        'passwordN':'Please enter the new password',
        'passwordO':'Please enter the new password again',
        'ConfirmEdit':'Confirm Edit',
        'inconsistent':'two passwords are inconsistent',
        'passwordLeng612':'The password length is 6-12 bits',
        'editSuccess':'Edit Success',
        'type':'type',
        'Award':'Award',
        'About':'About us',
        'Invitation':'Invitation mechanism',
        'InvitationD':'Invitation details',
        'TeamMembers':'Team members',
        'ReceivedReward':'Received reward',
        'lowerNumber':'lower Number',
        'operation':'operation',
        'directlyunder':'directly Under',
        'secondlevel':'second level',
        'subordinates':'subordinates',
        // 关于
        'abouIn':"BC hash international is a subsidiary of BC group. The group's business is deeply engaged in the research and development of block games. It has cooperated with Vietnam's technical team to create the meta universe block game axie, which is popular all over the world and has made remarkable achievements. Under the authorization of the Philippine government gambling Association, it is the only enterprise that has obtained the blockchain hash gambling license. It will create the only enterprise in the world that subverts traditional gambling with a fair, just and open gambling environment, The traditional gambling trust mechanism will be integrated into the blockchain, so that it can not be forged, transparent and verifiable, and create a new year of new gambling. BC group will join hands with you to create excellence and jointly learn from the industry's glory!",
        'about1-1':'Minimalist and fast',
        'about1-2':'We pursue a minimalist process / fast payout',
        'about1-3':'Just need a [decentralized] digital wallet to participate in the lottery',
        'about1-4':'No restrictions on playing methods, strategies and devices, anytime, anywhere',
        'about1-5':'After winning the prize, the account will be automatically received within 10 seconds. The game is uninterrupted and the experience is full',
        'about2-1':"We are committed to ensuring the security of customers' information and property",
        'about2-2':'No frozen card risk, no need to recharge deposit',
        'about2-3':'No need to review withdrawal application',
        'about2-4':'There is no flow limit for payment',
        'about2-5':'The platform fund pool can be checked at any time, and there is no need to worry about the rupture of the platform fund',
        'about3-1':"We defend everyone's right to justice",
        'about3-2':'The block hash value is randomly generated by the decentralized blockchain network, and anyone can query on the blockchain anytime, anywhere',
        'about3-3':'Truly realize that the platform cannot manipulate data, and all records are transparent and traceable',
        // 帮助中心
        'HelpCenter':'Help Center',
        'commonProblem':'Problem',
        'helpT1':"Q1：What is a block hash(Block hash)？",
        'helpM1':"A block hash is the DNA of a piece of data, and each block hash is unique, random, and unbreakable.",
        'helpL1':"Click to view the introduction of hash value",
        'helpT2':"Q2：what is blockchain？",
        'helpM2':"Blockchain is essentially a shared database in which data or information is stored. It has the characteristics of non-forgery, traces throughout the process, traceability, openness and transparency, and collective maintenance.",
        'helpL2':"Click to view the introduction of Baidu Encyclopedia blockchain",
        'helpT3':"Q3：Centralized wallet? Decentralized wallet? Why can't the centralized wallet query the transfer?",
        'helpM3':"For example: your own wallet address is A, you transfer 10 USDT to address E, but you use the transaction hash value to query, you can only query a random account book address B (or C or D, etc.) of the service provider etc) transfers with E. Similarly, E cannot know whether the 10 USDTs were transferred by you, because E can only see that the account book address B transferred 10 USDTs to him. This is why centralized wallets cannot query real transfer information on the blockchain.",
        'helpL3':"Click to see the difference between blockchain wallets",
        'helpT4':"Q4：Why do I keep winning or not winning？",
        'helpM4':"Winning or not winning is a random event, and the frequency of random events will tend to a stable value. In the non-iterative case, the probability of things appearing is equal to everyone, in this case, everyone is fair. At the same time, random events will follow the law of large numbers (under the condition that the experiment is unchanged, repeat the experiment for many times, the frequency of random events is similar to its probability definition of the law of large numbers), and in the case of increasing frequency of transfers, winning or not winning the lottery will tend to a stable value. So please continue to transfer money. As the frequency of transfers increases, the total probability of winning the lottery will increase.",
   
        'helpT5':"Q5：Why is it delayed？",
        'helpM5':"Due to the blockchain technology, in order to ensure the security of funds, multiple calculations, comparisons, and confirmations will be performed. So the calculation speed is slower, but it is more expensive in safety.",
        'helpL5':"Click to view the speed dilemma of the blockchain: <Trust is more expensive, slow is just right>",
        'helpT6':"Q6：Why are there so many types of USDT options when transferring money? Which one should I choose?",
        'helpM6':"There are many well-known public chains around the world that support USDT transfers, and Lucky Hash is based on TRON TRC20 technology. Therefore, the transfer address of Lucky Hash belongs to USDT-TRC20, and you should choose USDT-TRC20; if you choose another type, Lucky Hash cannot receive your USDT, which will cause losses to both you and Lucky Hash.",
        'helpL6':"Click to view the introduction of the world-renowned public chain",
        'helpT7':"Q7：What should I do if I transfer to the wrong address or select the wrong type of currency when transferring coins? Can it be recovered?",
        'helpM7':"For example, you should choose the USDT-TRC20 type when you transfer money to us, but if you choose another type of USDT-OMNI or USDT-ERC20, then the coins will be lost, which cannot be recovered.",
    }
}
