<!--
 * @Author: yhz 11231852@qq.com
 * @Date: 2023-12-23 00:31:38
 * @LastEditors: yhz 11231852@qq.com
 * @LastEditTime: 2023-12-23 01:00:43
 * @FilePath: \web-app\src\views\paymentCompleted\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="paymentCompleted">
    <!-- <van-cell title="测试显示分享面板" @click="showShare = true" /> -->
    <van-share-sheet
      v-model="showShare"
      title="立即分享给好友"
      :options="options"
      @select="onSelect"
    />
    <!-- <div class="icon">
      <img src="@/assets/images/icon_success.png" alt="" srcset="" />
      <p>支付成功</p>
    </div> -->
    <!-- 支付信息 -->
    <!-- <div class="pay-info">
      <van-cell-group :border="false">
        <van-cell title="支付金额" value="￥123" />
        <van-cell title="订单编号" value="2023101945523397" />
      </van-cell-group>
    </div> -->
    <!-- 优惠券 -->
    <!-- <div class="coupon">
      <div class="coupon-l">￥20</div>
      <div class="coupon-r">
        <div class="name">优惠券标题</div>
        <div class="state">已领取</div>
      </div>
    </div> -->
    <!-- 商家广告图 -->
    <div class="business-notice">
      <img :src="wxpic" alt="" srcset="" />
      <p>以上的订单内容由商家提供</p>
    </div>
    <!-- 广告 -->
    <!-- <div class="notice">
      <img src="@/assets/images/notice.png" alt="" srcset="" />
    </div> -->

    <!-- <van-button @click="Share" type="primary" round block
      >一键分享视频</van-button
    > -->
    <!-- 指引 -->
    <van-overlay :show="showGuide" @click="show = false">
      <div class="wrapper" @click.stop>
        <img :src="guideImg" class="img" alt="" />
      </div>
    </van-overlay>

    <van-popup v-model="showPrize" round>
      <!-- 转盘抽奖 -->
      <div class="prize-box">
        <div class="prize-content">
          <!-- 转盘的背景盒子，JS填充奖品背景 -->
          <div class="prize-list" id="prize-list"></div>

          <!-- 转盘的奖品盒子，JS填充奖品 -->
          <div class="prize-reward" id="prize-reward">
            <!-- 奖项渲染 -->
            <!-- <div
              class="prize-item"
              data-id="1" style="transform: rotate(45deg) translate(-50%, 44px);
                color: #f56134;width: 80px;">
              <div class="prize-name">测试奖品1</div>
              <div class="prize-icon">
                <img src="undefined" />
              </div>
            </div> -->
          </div>
        </div>
        <!-- 点击抽奖按钮 -->
        <div class="prize-button" id="prize-button" @click="doActivePrize">
          <img src="@/assets/images/kaishichoujiang.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>
<script>
import API from "@/api/api";
import dySdk from "@/assets/js/douyin_open.umd";
//import axios from "axios";
import $ from "jquery";
import wx from "weixin-js-sdk";
export default {
  name: "home",
  data() {
    return {
      showGuide: false,
      guideImg: require("@/assets/images/share.jpg"),
      showShare: false,
      options: [
        { id: "1", name: "微信", icon: require("@/assets/images/wx.jpg") },
        { id: "2", name: "抖音", icon: require("@/assets/images/dy.jpeg") },
      ],
      wxpic: "",
      appId: "wxc04364be5867ff13", // 正式
      shopId: 0,
      detail: {}, // 商店详情
      videoUrl: "",
      shareVideoUrl: "",
      title: "",
      tag: "",
      fileid: 0,
      isShare: false,
      share_id: "",
      sortList:[],
      showPrize: false, // 显示抽奖
      awardsList: [], // 奖项数据
      openid:"",
      dyShareState:0,
      intervalTimer:null,
      isHideActive:false,
      dyCode:"",
      isWxWeb:false,
    };
  },
  created() {
    this.shopId = this.$route.query.shopId;
    this.share_id = this.$route.query.share_id;
    this.openid=this.$route.query.openid;
    this.dyCode=this.$route.query.code
    //alert(this.share_id);
    if (this.share_id) {
      this.showGuide = true;
    } else {
      this.showGuide = false;
    }
    //console.log(this.shopId);
    this.getWxConfig();
  

    var userAgentStr = window.navigator.userAgent;
    //alert(userAgentStr);
    if (/MicroMessenger/.test(userAgentStr)) {
      this.isShare = false;
      this.isWxWeb=true;
    } else if (/AlipayClient/.test(userAgentStr)) {
    } else if (/aweme/.test(userAgentStr)) {
      this.getDyCode();
    } else {
      this.isShare = true;
      this.showGuide = false;
      if(/android/i.test(navigator.userAgent)){
        location.href="snssdk1128://webview?url=https%3A%2F%2Fh5.womaidan.com%2FpaymentCompleted%3FshopId%3D"+this.shopId+"%26share_id%3D"+this.share_id+"%26state%3Dorder_2024021797047654%26pay_type%3D1%26user_code%3D1%26jump%3DAds%26account%3Dorder_2024021797047654%26istype%3D2%26isAds%3D1%26order%3D2024021797047654%26sex%3D0%26use%3Dpay%26isuser%3D1%26user_code%3D1%26jump%3DAds%26account%3Dorder_2024021797047654%26istype%3D2%26isAds%3D1%26order%3D2024021797047654%26sex%3D0%26use%3Dpay%26isuser%3D1";
      }
      //
    }
    this.getShopDetailById();
    if(this.share_id){
      this.intervalTimer =setInterval(this.getDyShareResult, 1000);
    }
    
    //this.getShopCoupon();
  },
  mounted() {},
  methods: {
    // 分享选择确认
    onSelect(option, index) {
      this.showGuide = true; // 显示指引图
      this.showShare = false; // 隐藏分享面板

      // 生成自定义链接
      // const targetUrl = 'http://pxkj.ld-top.com';
      // const openInBrowserUrl = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${this.appId}&redirect_uri=${encodeURIComponent(targetUrl)}&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect`;
      // // 打开浏览器
      // window.location.href = openInBrowserUrl;
    },

    Share() {
      if (this.isShare) {
        //alert("1111");
        API.shareDYVideo(this.share_id).then((res) => {
          //alert("1111");
          if (res.code == 200) {
            API.getShareCardId(this.detail.share_card_id).then((res2) => {
              if (res2.code == 200) {
                //this.showGuide=true;
                //console.log(res2)
                //alert(res.data);
                location.href = res.data;
              } else {
                //alert(res.data);
                //this.showGuide=true;
                //console.log(res2)
                location.href = res.data;
              }
            });
          }
        });
      } else {
        let param = {
          shopId: this.shopId,
          fileId: this.fileid,
          videoPath: this.videoUrl,
          title: this.title,
          tag: encodeURIComponent(this.tag),
          poi_id: this.detail.poi_id,
        };
        API.shareDYVideo1(param).then((res) => {
          if (res.code == 200) {
            let share_id = this.getQueryString("state", res.data);
            API.getShareCardId(this.detail.share_card_id).then((res2) => {
              if (res2.code == 200) {
                //this.showGuide=true;
                //alert(this.getQueryString("state",res.data));
                //console.log(res2)
                // router.use('/view', function (req, res, next) {
                //     var path = req.path;
                //     res.redirect('https://www.xxx.com/view' + path + '?xxx=xxx');
                // });
                location.href = location.href + "&share_id=" + share_id;
              } else {
                //this.showGuide=true;
                location.href = location.href + "&share_id=" + share_id;
                //alert(this.getQueryString("state",res.data));
                //console.log(res2)
                //location.href=res.data;
              }
            });
          }
        });
      }
    },
    getDyCode(){
      var url = encodeURIComponent(location.href);
      //alert(url);
      API.dyConfig(url).then((res) => {
        if(res.code==200){
          dySdk.config({
	          params: {
		          client_key: res.client_key, // clientKey在你的网页应用申请通过后得到
		          signature: res.signature, // 服务端计算的签名，该签名被抖音开放平台验证通过后方可调用jsb方法
		          timestamp: String(res.timestamp), // 时间戳必须是字符串
		          nonce_str: res.nonce_str,//生成签名用的随机字符串
		          url: res.url, // 为应用申请的 JSB 安全域名下的链接，需要携带协议。e.g. https://jsb.security.domain/page.html
	          },
          });
          //alert(res.signature);
          dySdk.error(res => {
            //alert(JSON.stringify(res));
          });
          dySdk.ready(() => {
	// Config Ready回调
	          dySdk.showOpenAuth({
		        params: {
			        client_key: res.client_key, // clientKey 在你的网页应用申请通过后得到
              scopeList: ['data.external.item'], // clientKey 在你的网页应用申请通过后得到
			        scopes: {
				      //此处配置想要获取的用户权限
				        user_info: 0,
                 // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
			        },
			        response_type: "code", // 默认填‘code’
		        },
		        success: (rescode) => {

              this.dyCode=rescode.ticket;
              if(this.dyCode){
                //this.dyLogin();
              }
              
              //alert(JSON.stringify(rescode));
			        //拿到返回值后， res.response.ticket相当于微信中的code；
			        //调用接口把ticket传给后台，后台拿着这个ticket调用抖音api获取用户信息
		        },
		        error: (err) => {
              //alert(JSON.stringify(err));
		        }
	        })
        })
        }
      });
    },

    getQueryString(name, url) {
      var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
      var r = url.substr(1).match(reg);
      if (r != null) {
        return unescape(r[2]);
      }
      return null;
    },
    getWxConfig() {
      let curl = location.href.split("#")[0];
      //console.log(url);
      let param = {
        url: curl,
        client: "JSAPI",
      };
      API.wxConfig(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          wx.config({
            debug: false, // 开发阶段设为true，生产阶段设为false
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: [
              "onMenuShareTimeline",
              "updateTimelineShareData",
              "updateAppMessageShareData",
            ], // 需要使用的API列表
          });
        }
      });
    },
    getVideoUrl() {
      API.getVideoUrl(this.shopId).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.videoUrl = "https://pxkj.file.ld-top.com" + res.data.url;
          this.title = res.data.title;
          this.tag = res.data.tag;
          this.fileid = res.data.id;
          var that = this;

          //如果后台配置此商家为抖音分享视频
          
          if (that.share_id) {
            //如果有share_id，直接到抖音
            that.showGuide = true;
            
            //alert(that.showGuide);
            API.shareDYVideo(this.share_id).then((res) => {
              //alert("1111");
              if (res.code == 200) {
                if (that.sortList[0].val > 0) {
                  console.log(this.dyShareState);
                  if(that.dyShareState==1 && that.isHideActive==false){
                        clearInterval(that.intervalTimer);
                        if(that.sortList[2].type == "sortActive"){
                          that.getShopActive();
                        }
                        var pa={
                          openid:that.openid,
                          shopName:that.detail.shopName
                        };
                        API.postMsg(pa).then((res) => {
                          if(res.code==200){
                            //alert("奖励已发放，请留意信息查看！");
                            if (!this.openid && this.isWxWeb==true){
                              location.href="https://mp.weixin.qq.com/mp/subscribemsg?action=get_confirm&appid=wxc04364be5867ff13&scene=1000&template_id=oyIQnFlFxNEK-0PPws3K81nriV2ZbL0HYEPRDv1PKG0&redirect_url="+encodeURIComponent(location.href)+"&reserved=test#wechat_redirect"
                            }

                          }
                        })
                      }
                  //console.log(that.dyShareState);
                  
                  
                }
                // console.log(res.data);
                // var iframe = document.createElement("iframe");
				        // iframe.style.display = "none";
				        // iframe.src = url;
				        // document.body.appendChild(iframe);
                
               location.href = res.data;
                

                //location.href = res.data;
                
                // API.getShareCardId(this.detail.share_card_id).then((res2) => {
                //   if (res2.code == 200) {
                //     alert(res.data);
                //     location.href = res.data;
                //   } else {
                //     location.href = res.data;
                //   }
                // });
              }
            });
          } else {
            //如果没有share_id，先获取连接，跳转
            let param = {
              shopId: that.shopId,
              fileId: that.fileid,
              videoPath: that.videoUrl,
              title: that.title,
              tag: encodeURIComponent(that.tag),
              poi_id: that.detail.poi_id,
            };
            console.log(param);
            API.shareDYVideo1(param).then((res) => {
              if (res.code == 200) {
                let share_id = this.getQueryString("state", res.data);
                location.href = location.href + "&share_id=" + share_id;
               

                // API.getShareCardId(this.detail.share_card_id).then((res2) => {
                //   if (res2.code == 200) {
                //     //this.showGuide=true;
                //     //alert(this.getQueryString("state",res.data));
                //     //console.log(res2)
                //     location.href = location.href + "&share_id=" + share_id;
                //   } else {
                //     //this.showGuide=true;
                //     location.href = location.href + "&share_id=" + share_id;
                //     //alert(this.getQueryString("state",res.data));
                //     //console.log(res2)
                //     //location.href=res.data;
                //   }
                // });
              }
            });
          }
        }
      });
    },
    getDyShareResult(){
      API.dyShareResult(this.share_id).then((result) => {
                    if(result.code==200){
                      this.dyShareState=result.data.shareState;
                      if(this.dyShareState==1){
                        this.getVideoUrl();
                      }
                    }
                  });

    },
    getShopDetailById() {
      API.getShopDetailById(this.shopId).then((res) => {
        if (res.code == 200) {
          
          let shopInfo = res.data;
          this.detail = res.data;
          var that = this;
          that.setSort(that.detail);
          console.log(that.sortList);
          //return;
          if (that.sortList[1].type == "sortActive" && that.sortList[0].val > 0) {
            if(!that.share_id){
              that.getShopActive();
            } else{
              that.getVideoUrl();
            }
        // 排序第一为抽奖时
             
          }else{
            that.getVideoUrl();

          }

          

          // if (that.detail.shareType == 0) {
          //   //that.wxpic="https://pxkj-1322384497.cos.ap-guangzhou.myqcloud.com"+that.detail.wxPic;
          //   location.href = "/wxshare?shopId=" + that.shopId+"&isShare=true";
          // } else {
          //   that.wxpic =
          //     "https://pxkj-1322384497.cos.ap-guangzhou.myqcloud.com" +
          //     that.detail.headPic;
          // }
          that.wxpic =
              "https://pxkj-1322384497.cos.ap-guangzhou.myqcloud.com" +
              that.detail.headPic;
          
          wx.ready(function () {
            //alert("1111");
            var timestamp = new Date().getTime();
            wx.updateTimelineShareData({
              title: that.detail.shopShortName, // 分享标题
              desc: that.detail.shopShortName, // 分享描述
              link: encodeURI(
                "https://h5.womaidan.com/wxshare?shopId=" +
                  this.shopId +
                  "&t=" +
                  timestamp+"&from=share"
              ), // 分享链接
              imgUrl: "https://pxkj.file.ld-top.com/" + that.detail.shopPic, // 分享图标
              type: "video", // 分享类型,music、video或link，不填默认为link
              dataUrl: that.videoUrl,
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          });
          //console.log(this.detail);
          //this.getWxConfig();
        }
      });
    },
    setSort(detail) {
      let obj = {
        sortActive: detail.sortActive,
        sortPay: detail.sortPay,
        sortShare: detail.sortShare,
      };
      let list = [];
      for (let key in obj) {
        let newObj = {};
        (newObj["val"] = obj[key]), (newObj["type"] = key), list.push(newObj);
      }
      this.sortList = list.sort((a, b) => a.val - b.val);
      console.log(this.sortList);
    },

    getShopActive() {
      API.getShopActive(this.shopId).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.getActivePrize(data.id);
          this.activeId = data.id;
          this.showPrize = true;
        }
      });
    },
    // 获取奖项
    getActivePrize(activeId) {
      API.getActivePrize(activeId).then((res) => {
        if (res.code == 200) {
          let obj = {
            activeId: 1,
            couponId: 10,
            couponName: "谢谢参与",
            createBy: "1",
            createTime: "2024-01-07 23:18:33",
            id: 999,
            state: 0,
            ids: null,
            params: {},
            prizeGetNum: 1,
            prizeName: "谢谢参与",
            prizeNum: 1,
            remark: null,
            searchValue: null,
            updateBy: "18573780510",
            updateTime: "2024-01-06 14:56:11",
          };
          let awardsList = res.data;
          awardsList.push(obj);
          this.awardsList = awardsList;
          this.prize();
        }
      });
    },
     // 渲染抽奖
     prize(data) {
      let reward = "";
      let count = this.awardsList.length;
      const zhuanpanImg = require(`@/assets/images/zhuanpan_${count}.png`);
      let rotate = 360 / count;
      let itemRotate = 360 / 2 / count;
      $(".prize-box .prize-list").css({
        "background-image": `url('${zhuanpanImg}')`,
        transform: `rotate(${itemRotate}deg)`,
      });
      // 设置数量奖品的旋转角度值
      let width = 100;
      // 组装背景和奖品的标签，且设置奖品的旋转角度
      for (let i = 0; i < count; i++) {
        // list += '<div class="prize-li"></div>';
        reward +=
          '<div class="prize-item" data-id="' +
          this.awardsList[i]["id"] +
          '" style="transform: rotate(' +
          (rotate * (i + 1) - itemRotate) +
          "deg) translate(-50%,20px);color: #F56134;width: " +
          width +
          'px;">\n' +
          '                                <div class="prize-name">\n' +
          this.awardsList[i]["prizeName"] +
          "                                </div>\n" +
          "                            </div>";
      }
      // 填充内容
      $("#prize-reward").html(reward);
    },
    // 点击抽奖
    doActivePrize() {
      API.doActivePrize(this.activeId).then((res) => {
        if (res.code == 200) {
          // 转盘
          let data = res.data;
          console.log(data);
          if (data != null) {
            // 中奖
            this.turn(data);
          } else {
            let obj = {
              activeId: 1,
              couponId: 10,
              couponName: "谢谢参与",
              createBy: "1",
              createTime: "2024-01-07 23:18:33",
              id: 999,
              state: 0,
              ids: null,
              params: {},
              prizeGetNum: 0,
              prizeName: "谢谢参与",
              prizeNum: 1,
              remark: null,
              searchValue: null,
              updateBy: "18573780510",
              updateTime: "2024-01-06 14:56:11",
            };
            this.turn(obj);
          }
        }
      });
      
    },
    // 中奖或者未中奖，转盘转动
    turn(data) {
      let baseNum = 360 / this.awardsList.length;
      for (let i = 0; i < this.awardsList.length; i++) {
        let e = this.awardsList[i];
        if (e.id == data.id) {
          // + RandomNumBoth(5,baseNum-5)
          var degNum = 3600 - (i + 1) * baseNum + baseNum / 2;
          $(".prize-content").css({
            transition: "transform 6s cubic-bezier(0.25, 0.1, 0.01, 1)",
            transform: "rotate(" + degNum + "deg)",
          });
          setTimeout(() => {
            this.prizeHide(data);
          }, 6500);
          
        }
      }
    },
    // 抽奖隐藏
    prizeHide(data) {
      if (data.prizeGetNum != 0) {
        this.$toast(`恭喜您，获得${data.couponName}`);
      } else {
        this.$toast(`很遗憾，谢谢参与`);
      }
      this.showPrize = false;
      this.isHideActive=true;
      this.getVideoUrl();
    },
    // 转盘复位
    reset() {
      $(".prize-content").css({ transition: "", transform: "rotate(0deg)" });
    },
  },


};
</script>
<style lang="scss" scoped>
.paymentCompleted {
  width: 100%;
  //   height: 100vh;
  background: #ffffff;
  padding: 30px 30px 200px 30px;
  box-sizing: border-box;
  .icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    img {
      width: 96px;
      height: 96px;
      margin-bottom: 8px;
    }
    p {
      font-size: 32px;
      color: #333;
    }
  }
  .pay-info {
    margin-bottom: 40px;
  }
  .coupon {
    width: 100%;
    height: 96px;
    // border: 1px solid #f0f0f0;
    background: url("@/assets/images/coupon.png") no-repeat center;
    background-size: 100% 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 48px;
    .coupon-l {
      width: 150px;
      font-size: 32px;
      color: #ff6619;
      font-weight: 500;
      text-align: center;
    }
    .coupon-r {
      flex: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 32px;
      box-sizing: border-box;
      .name {
        color: #666666;
        font-size: 32px;
      }
      .state {
        color: #ff6619;
        font-size: 24px;
        font-weight: 400;
      }
    }
  }
  .business-notice {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
    img {
      width: 100%;
      height: auto;
      margin-bottom: 32px;
    }
    p {
      font-size: 24px;
      color: #999;
    }
  }
  .notice {
    width: 100%;
    height: 172px;
    border-radius: 16px;
    position: fixed;
    left: 0;
    bottom: 30px;
    padding: 0 30px;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
    }
  }
  // 指引
  .wrapper {
    width: 100vw;
    height: 100vh;
    .img {
      width: 100%;
      height: 100%;
    }
  }
}

/* 翻牌抽奖 */
@keyframes flipAnimate {
  0% {
    transform: rotateY(0deg) translateZ(-10px);
    -webkit-transform: rotateY(0deg) translateZ(-10px);
    -moz-transform: rotateY(0deg) translateZ(-10px);
    -ms-transform: rotateY(0deg) translateZ(-10px);
    -o-transform: rotateY(0deg) translateZ(-10px);
  }

  30% {
    transform: rotateY(540deg) translateZ(-10px);
    -webkit-transform: rotateY(540deg) translateZ(-10px);
    -moz-transform: rotateY(540deg) translateZ(-10px);
    -ms-transform: rotateY(540deg) translateZ(-10px);
    -o-transform: rotateY(540deg) translateZ(-10px);
    left: 245px;
    width: 25px;
    height: 344px;
  }

  90% {
    transform: rotateY(540deg) translateZ(-10px);
    -webkit-transform: rotateY(540deg) translateZ(-10px);
    -moz-transform: rotateY(540deg) translateZ(-10px);
    -ms-transform: rotateY(540deg) translateZ(-10px);
    -o-transform: rotateY(540deg) translateZ(-10px);
    left: 245px;
    width: 25px;
    height: 344px;
  }

  100% {
    transform: rotateY(540deg) translateZ(-10px);
    -webkit-transform: rotateY(540deg) translateZ(-10px);
    -moz-transform: rotateY(540deg) translateZ(-10px);
    -ms-transform: rotateY(540deg) translateZ(-10px);
    -o-transform: rotateY(540deg) translateZ(-10px);
    left: 255px;
    width: 230px;
    height: 324px;
  }
}

@keyframes flipLeft {
  0% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
  }

  50% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
    left: 76px;
  }

  100% {
    transform: rotateY(180deg) translateZ(1px);
    -webkit-transform: rotateY(180deg) translateZ(1px);
    -moz-transform: rotateY(180deg) translateZ(1px);
    -ms-transform: rotateY(180deg) translateZ(1px);
    -o-transform: rotateY(180deg) translateZ(1px);
    left: 76px;
  }
}

@keyframes flipRight {
  0% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
  }

  50% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
    left: 476px;
  }

  100% {
    transform: rotateY(180deg) translateZ(1px);
    -webkit-transform: rotateY(180deg) translateZ(1px);
    -moz-transform: rotateY(180deg) translateZ(1px);
    -ms-transform: rotateY(180deg) translateZ(1px);
    -o-transform: rotateY(180deg) translateZ(1px);
    left: 476px;
  }
}

.flopDraw-box {
  width: 740px;
  height: 538px;
  // position: absolute;
  // left: 50%;
  // top: 398px;
  // transform: translateX(-50%);
  overflow: hidden;
  background: url("@/assets/images/fanpai.png") no-repeat center;
  background-size: 100% 100%;
  // display: none;
}

.flip_wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  perspective: 800px;
  /*perspective属性定义3D元素距视图的距离,以像素计。该属性允许您改变 3D 元素查看 3D 元素的视图。*/
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  -o-perspective: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 76px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: unset;
}

.flip {
  width: 188px;
  height: 266px;
  transition: all 1s ease;
  /*为翻牌添加过渡效果*/
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transform-style: preserve-3d;
  /*子元素将保留其 3D 位置。*/
  -webkit-transform-style: preserve-3d;
  position: absolute;
}

.flip:first-of-type {
  left: 76px;
}

.flip:nth-of-type(2) {
  left: 276px;
}

.flip:last-of-type {
  left: 476px;
}

.flip.active {
  z-index: 999;
  /* transform: translateZ(100px);
    -webkit-transform: translateZ(100px);
    -moz-transform: translateZ(100px);
    -ms-transform: translateZ(100px);
    -o-transform: translateZ(100px); */

  animation-name: flipAnimate;
  -webkit-animation-name: flipAnimate;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.flip.remainingFlip {
  z-index: 99;
  /* transform:rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
    -ms-transform:rotateY(180deg);
    -o-transform:rotateY(180deg); */
}

.remainingFlip.left {
  animation-name: flipLeft;
  -webkit-animation-name: flipLeft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.remainingFlip.right {
  animation-name: flipRight;
  -webkit-animation-name: flipRight;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.side {
  backface-visibility: hidden;
  /*背对屏幕时隐藏*/
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  /*让背面和正面重叠*/
}

.side p {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -moz-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  -o-writing-mode: vertical-lr;
  font-size: 36px;
  color: #ff9515;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.remainingFlip p {
  background: rgba(0, 0, 0, 0.2);
}

.front img,
.back img {
  width: 100%;
  height: 100%;
}

.front {
  z-index: 2;
  /*让正面朝上*/
}

.back {
  transform: rotateY(180deg) translateZ(1px);
  -webkit-transform: rotateY(180deg) translateZ(1px);
  -moz-transform: rotateY(180deg) translateZ(1px);
  -ms-transform: rotateY(180deg) translateZ(1px);
  -o-transform: rotateY(180deg) translateZ(1px);
}
/* 转盘 */
::v-deep(.prize-box) {
  // position: absolute;
  width: 598px;
  height: 598px;
  // left: 50%;
  // top: 348px;
  // transform: translateX(-50%);
  border-radius: 100%;
  overflow: hidden;
  // display: none;
  .prize-content {
    width: 598px;
    height: 598px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    .prize-list {
      width: 598px;
      height: 598px;
      background: url("@/assets/images/zhuanpan_4.png") no-repeat;
      /* transform: rotate(45deg); */
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 100%;
      position: relative;
    }
  }
  .prize-icon {
    width: 55px;
    height: 55px;
    margin: 10px auto 0px auto;
  }
  .prize-icon img {
    width: 100%;
    height: 100%;
  }

  .prize-button {
    width: 163px;
    height: 201px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  /* .prize-list {
    width: 498px;
    height: 498px;
    border-radius: 100%;
    position: relative;
}
.prize-li {
    position: absolute;
    transform-origin: left top;
    left: 299px;
    top: 299px;
    width: 299px;
    height: 299px;
    box-sizing: border-box;
} */
  .prize-reward {
    width: 598px;
    height: 598px;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 99;
  }
  .prize-reward .prize-item {
    width: 10px;
    height: 250px;
    position: absolute;
    top: 40px;
    z-index: 99;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
  .prize-button img {
    width: 100%;
    height: 100%;
  }
  .prize-item .prize-name {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 30px;
  }
}
</style>