<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-18 14:18:02
 * @LastEditTime: 2022-09-02 22:25:25
 * @FilePath: \web-app\src\views\login.vue
-->
<template>
  <div class="login">
    <van-form @submit="onSubmit">
      <van-field
        v-model="username"
        name="用户名"
        label="用户名"
        placeholder="用户名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="password"
        type="password"
        name="密码"
        label="密码"
        placeholder="密码"
        :rules="[{ required: true, message: '请填写密码' }]"
      />
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import API from "@/api/api.js";
export default {
  data() {
    return {
      username: "",
      password: "",
    };
  },
  created() {
    // this.getWXcode();
  },
  methods: {
    getWXcode() {
      let appId = "wx19a143258f886d23"; //示例，公众号appid
      let redirectUri = encodeURIComponent("http://192.168.1.3:5001/callback");
      //去微信公众号官方授权页根据appid获取code，之后会携带code值自行返回redirect_uri页面
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        appId +
        "&redirect_uri=" +
        redirectUri +
        "&response_type=code&scope=snsapi_base#wechat_redirect";
    },

    onSubmit(values) {
      console.log("submit", values);
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
}
</style>