<template>
    <div class="transactionAmount">
      <div class="hear">
        <h4>优惠券管理</h4>
        
      </div>
      <div class="list-box">
        <!-- <van-search
          v-model="searchVal"
          placeholder="请输入商户或业务专员名称进行查询"
        />
        <ul>
         
            <li class="atv">业务专员数量   {{total}}</li>
        </ul> -->
  
        <div class="lists" style="margin-bottom:200px;">
          <ul v-for="(item, index) in userList"
            :key="index">
            <li>卡券名称：{{item.couponName }}<p style="margin-top:10px">卡券类型：{{couponTye(item.couponType)}}</p></li>
            <li>卡券面额：{{item.couponValue}}<p style="margin-top:10px;" @click="updateStatus(item.id,item.couponState)">状态：<van-switch v-model="item.state" size="22px" /></p><span style="color:#fff;margin-left:10px; float:right;width:70px;height:30px; background-color:#ff0000;text-align:center; position: relative; top:-40px;right:-20px;padding-top:5px;" @click="delCard(item.id)">刪除</span></li>
            
          </ul>
          <!-- <van-list
            v-model="loading"
            :finished="finished"
            finished-text="没有更多了"
            @load="onLoad"
            class="lists"
          > -->
        
          <!-- </van-list> -->
        </div>
        <div class="btn"  @click="Add()">新增卡券</div>
      </div>
      <div class="home" @click="goHome"><img src="@/assets/images/return.png"/></div>
    </div>
  </template>
  
  <script>
  import API from "@/api/api_shop.js";
  import { Dialog } from 'vant';
  import { Switch } from 'vant'
  export default {
    name: "transactionAmount",
    data() {
      return {
        tabList: [
          {
            name: "今日",
            id: "1",
          },
          {
            name: "近7天",
            id: "2",
          },
          {
            name: "近30天",
            id: "3",
          },
          // {
          //   name: "自定义",
          //   id: "4",
          // },
        ],
        total:0,
        userList:[],
        shopList:[
          
        ],
        current: 0,
        lists: [],
        loading: false,
        finished: false,
        searchVal:"",
        m0:52006,
        m1:49864,
        userType:""
  
      };
    },
    created() {
        this.getCouponList()
      
    },
    methods: {

      Add(id){
        if(id){
          location.href="/shopCenter/coupon/add?id="+id;
        }else{
          location.href="/shopCenter/coupon/add";
        }
      },
        filterTime(time, type) {
        let str = "";
        let date = new Date(time);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let minute = date.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        if (type == "time") str = `${y}-${m}-${d}  ${h}:${minute}:${s}`;
        if (type == "date") str = `${y}-${m}-${d}`;
        return str;
      },
    goHome(){
      location.href="/shopCenter/index";
    },

    delCard(id){
      Dialog.confirm({
        title: '优惠券删除',
        message: '确认删除此内容'
    }).then(() => {
      API.delCard(id).then((res) => {
        if (res.code == 200) {
          alert("删除成功");
          location.href="/shopCenter/coupon";
        }
      });
      // on confirm
    }).catch(() => {
  // on cancel
    });
    },
      goDetails(id){
        location.href="/team/details?userId="+id;
      },
      getCouponList(){
        API.getCouponList().then((res) => {
        if (res.code == 200) {
          this.userList=res.rows;
          for(var i=0;i<this.userList.length;i++){
            this.userList[i].state=this.userList[i].couponState==1?true:false;
          }
        }
      });
      },
      couponTye(i){
        switch (i) {
            case 0:
                return "优惠券";    
            case 1:
                return "满减券";
            case 2:
                return "折扣券"
            case 3:
                return "现金券"
            case 4:
                return "实物券";
            default:
                return ""                
            }
      },
      createQrcode(){
        API.createQrcode().then((res) => {
        if (res.code == 200) {
          //location.href="/team/qrcode?img="

          let img ="https://pxkj-1322384497.cos.ap-guangzhou.myqcloud.com"+res.data;
          let code=res.userNo;

          location.href="/team/qrcode?id="+ code +"&img="+img;
        }
      });
      },
      updateStatus(id,state){
        if(state==1){
          state=0;
        }else{
          state=1;
        }
        let para={
          id,
          state,
        };
        API.updateStatus(para).then((res) => {
          if (res.code == 200) {
            alert("设置成功");
            location.href="/shopCenter/coupon";
          }
        });
        
      },
      tabClick(i) {
        this.current = i;
        //alert(this.current)
        if(this.current==1){
          this.m0=313832;
          this.shopList[0].deal=289140
          this.shopList[0].income=578.28
  
          this.shopList[1].deal=24692
          this.shopList[1].income=49.38
        }
        if(this.current==2){
          this.m0=623004;
  
          this.shopList[0].deal=510003
          this.shopList[0].income=1020
  
          this.shopList[1].deal=113001
          this.shopList[1].income=226
        }
      },
      
      onLoad() {},
    },
  };
  </script>
  
  <style  lang="scss" scoped>
  
  .home{
  position:fixed;
  top:3%;
  left: 5%;
  img{
    width: 20%;
    height: 20%;
  }
}
  .btn{
    position:fixed;
    bottom: 20pt;
    width: 93%;
    text-align:center;
    font-size: 14pt;
    background-color: #ffcb51;
    height: 40pt;
    padding-top: 15pt;
  }
   ul{float:left;width: 100%;height: 50pt; background-color: #fff; margin-bottom: 20pt;}
    ul li{float: left; width: 100%; text-align: center; font-size: 14pt; margin-top: 12pt; height: 30pt; padding-top:10pt; }
    .atv{background-color: #ffcb51; color: rgb(19, 18, 18);}

  .transactionAmount {
    background: #fff;
    .hear {
      width: 100%;
      height: 100px;
      background-color: #ffcb51;
      background-image: linear-gradient(62deg, #ffcb51 0%, #f7d568 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 30px 0px;
      
      h4 {
        font-size: 32px;
      }
      .tab {
        .item {
          font-size: 24px;
          padding: 6px 10px;
          box-sizing: border-box;
          color: #666;
          background: #fff;
          margin-right: 16px;
          border-radius: 8px;
          &.active {
            color: #ff6619;
          }
        }
      }
      .money {
        text-align: center;
        .icon {
          font-size: 30px;
          color: #666;
        }
        .num {
          font-size: 50px;
          color: #000;
        }
        p {
          font-size: 24px;
          color: #000;
        }
      }
    }
    .list-box {
      padding: 20px;
      box-sizing: border-box;
      h4 {
        font-size: 36px;
        text-align: center;
        margin-bottom: 30px;
      }
      .lists {
        width: 100%;
        height: auto;
        overflow-y: auto;
        
        //   display: flex;
        //   justify-content: space-between;
        //   align-content: center;
        ul{float:left;width: 100%; height: 60pt; background-color: #fff; }
        ul li{float: left; width: 45%; text-align: center; font-size: 12pt;  height: 60pt; text-align: left; padding-left: 15px;margin-top:0px;border-bottom:1px solid #ccc; }
        .item-box {
          width: 100%;
          background: #eee;
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 20px;
          box-sizing: border-box;
          .name {
            font-size: 28px;
            color: #000;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .item {
            width: 100%;
            font-size: 26px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            .red {
              color: red;
            }
            .fontw700 {
              font-weight: 700;
            }
            p {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
    ol, ul{padding-left: 0px;}
  }
  </style>