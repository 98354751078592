function dateToTimestamp(dateString) {
    var match = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (match) {
      var year = parseInt(match[1]);
      var month = parseInt(match[2]);
      var day = parseInt(match[3]);
   
      var dateObj = new Date(year, month-1, day); // JavaScript的月份是从0开始的，所以要减1
      var timestamp = dateObj.getTime();
      return timestamp;
    }
    return null;
  }


  export default dateToTimestamp
