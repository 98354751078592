/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-08-05 16:20:27
 * @FilePath: \web-app\src\store\index.js
 */
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    token:'',
    classId:'',
    className:'选择班级',
    classInfo:{},
    studentInfo:{},
  },
  getters: {},
  mutations: {
    updataClassInfo(state,info) {
      state.classId = info.classId;
      state.className = info.className;
      state.classInfo = info;
    },
    updataStudentInfo(state,info) {
      state.studentInfo = info;
    },
    updataToken(state,token) {
      state.token = token;
    },
  },
  actions: {},
  modules: {},
});
