<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2024-01-04 08:12:02
 * @LastEditTime: 2024-01-04 08:12:02
 * @FilePath: \web-app-b\src\views\callback.vue
-->
<template>
    <div class="callback">
      {{ code }}
    </div>
  </template>
  <script>
  import API from "@/api/api.js";
  export default {
    data() {
      return {
        code:""
      };
    },
    created() {
        this.code = this.$route.query.code;
      this.getOpenId();
    },
    methods: {
        getOpenId() {
            API.getOpenId(this.code).then((res) => {
                console.log(res)
            })
        }
    },
  };
  </script>
  <style lang="scss" scoped>
  .login {
  }
  </style>