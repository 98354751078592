/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-08-05 16:50:21
 * @LastEditTime: 2022-08-05 16:58:47
 * @FilePath: \web-app\src\utils\geTel.js
 */
// 隐藏号码中间四位
function geTel(tel){
    console.log(tel);
    if(!tel) return false;
    var reg = /^(\d{3})\d{4}(\d{4})$/;  
    return tel.replace(reg, "$1****$2");
}



export default geTel;