/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-26 23:36:30
 * @LastEditors: yhz 11231852@qq.com
 * @LastEditTime: 2023-05-11 22:04:10
 * @FilePath: \web-app\src\assets\language\language-cn.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
    message: {
        'noData':'暂无数据！',
        //  客服
        'serverTip0':'在线客服',
        'serverTip1':'官方频道',
        'serverTip2':'走势图',
        'calendartitle':'日期选择',
        // 登录
        'pleaseLog':'您还未登录，请先登录',
        'Cancel':'取消',
        'confirm':'确定',
        'goindex':'去首页',
        'Register':'立即注册',
        'Account':'账号',
        'Password':'密码',
        'Welcome':'欢迎登录BC国际娱乐',
        'intAccount':'请输入账号',
        'inpPassword':'请输入密码',
        // 注册
        'placeholder1':'设置登陆账户5-10位，必须字母开头',
        'placeholder2':'设置登陆密码，不能和用户名相同',
        'placeholder3':'确认登陆密码',
        'placeholder4':'绑定trc20钱包地址',
        'placeholder5':'请输入验证码',
        'loginNow':'立即登录',
        'RegisterBtn':'注册',
        'RegisterTip1':'请输入账户',
        'RegisterTip2':'登陆账户5-10位，必须字母开头',
        'RegisterTip3':'请输入密码',
        'RegisterTip4':'密码长度大于等6',
        'RegisterTip5':'密码不能与用户名相同',
        'RegisterTip6':'请再次输入密码',
        'RegisterTip7':'两次密码不一致',
        'RegisterTip8':'请输入钱包地址',
        'RegisterTip9':'请输入验证码',
        // 首页
        'home':'首页',
        'lang':'语言切换',
        'en':'英文',
        'cn':'中文',
        'login':'登录',
        'more':'更多',
        'Notice':'公告',
        'NotNotice':'暂无公告',
        'official':'官方唯一派奖地址',
        'agmeItroduction':'游戏介绍',
        'amountLimit':'金额限制',
        'Odds':'赔率',
        'OnlineService':'如有疑问，请联系在线客服！',
        'participate':'立即参与',
        'day':'日榜',
        'week':'周榜',
        'month':'月榜',
        'address':'钱包地址',
        'win':'中奖',
        'process':'参与流程',
        'link':'查看教程',
        'Wallet':'注册钱包',
        'WalletTip':'数字币【去中心化】钱包下载地址',
        'currencyTip':'去交易所中购买USDT和TRX',
        'currency':'购买虚拟币',
        'TransferBet':'转账投注',
        'TransferBetTip':'注册本平台后向本平台规定的地址按规定金额转账后即成功参与，若中奖系统会自动结算3秒内派发到转账的钱包(注：必须使用去中心化钱包投注，交易所账户直接转入无法识别区块链转账的玩家实际地址。)',
        'Download':'辅助软件下载',
        'Platform':'平台',
        'URL':'网址',
        'Tutorial':'教程',
        'TianjiVPN':'天极加速VPN',
        'CycloneVPN':'旋风加速VPN',
        'Telegram':'Telegram',
        'Note':'注明',
        'Note1':'1.国内用户需要下载VPN翻墙软件，才能打开交易所和下载虚拟币钱包。',
        'Note2':'2.关注BC1盘官方频道和官方客服需要下载Telegram软件。',
        'Note3':'3.国内苹果用户，下载钱包以及交易所APP，需要切换应用商店，详情可以关注BC1盘官方频道，里面有详细的视频教程。频道链接：',
        'prizePoolBalance': '抽奖奖池',
        'prizePoolBalance1': '资金池',
        'haoli1':'壕礼大放送',
        'haoli2':'每晚00:00以',
        'haoli3':'数字抽奖',
        'haoli4':'形式',
        'haoli5':'大吉大利奖',
        'haoli6':'时来运转奖',
        'haoli7':'财运亨通奖',
        'haoli8':'事事如意奖',
        'haoli9':'中奖地址',
        'haoli10':'今日无地址中奖',
        'haoli11':'转账哈希',
        'haoli12':'转账区块号',
        'haoli13':'转账区块哈希',
        'haoli14':'下一区块号',
        'haoli15':'下一区块哈希',
        'haoli16':'达标地址',
        'haoli17':'今日无达标地址',
        'haoli18':'开奖结果',
        'haoli19':'投注区块哈希',
        'haoli120':'投注哈希',
        // 兑换
        'ExchangeT':'兑换',
        'Exchange':'兑换计算',
        'EnterQuantity':'输入数量查询',
        'todatRate':"今日汇率",
        'Scan':'扫码转账兑换',
        'Quantitylimit':'数量限制',
        'copy':'复制',
        'copyAddress':'复制地址',
        'or':'或',
        'Warm':'温馨提示',
        'Warm1':'1. 打开第三方数字钱包，扫码或复制地址转账。（无需注册无需授权，所有需授权类均有风险，本平台采用普通转账方式，请自行辨别）。',
        'Warm2':'2. 单笔转账数量请不要超过限额范围，低于单笔最低限额无法秒返，如有误转请及时联系客服处理。',
        'Warm3':'3. 平台将按官方时时汇率3秒内给您返还同等价值的TRX或USDT币。',
        'Record':'兑换记录',
        'Order':'兑换订单',
        'Amount':'兑换金额',
        'Arrival':'到账',
        'Time':'兑换时间',
        'NoData':'暂无数据',
        'NoMore':'没有更多了',
        'Loading':'加载中...',
        // 热门游戏
        'game':'游戏',
        'heat':'热',
        'HotGames':'热门游戏',
        'multiple':'倍',
        'PlayNow':'立即去玩',
        'Limit':'转账限额',
        'Tips':'提示',
        'TipsC1':'1.切勿使用交易所地址，否则导致无法收到返奖',
        'TipsC2':'2.只支持TRC20转账',
        'HowPlay':'玩法介绍',
        'jinqi':'您近期',
        'zoushi':'玩法走势图',
        'shuang':'双',
        'dan':'单',
        'da':'大',
        'xiao':'小',
        'ying':'赢',
        'shu':'输',
        'zhuang':'庄',
        'xian':'闲',
        'he':'和',
        'copySuccess':'复制成功',
        'noSupport':'该浏览器不支持自动复制',
        "guize":'游戏规则',
        "jieshou":'仅接受',
        'yesterday':'昨天',
        'today':'今天',

        // 活动
        'active':'活动',


        // 我的
        'me':'我的',
        'GameRecord':'游戏记录',
        'P&LRecord':'我的报表',
        'SecuritySettings':'安全设置',
        'TeamReport':'团队报表',
        'MyReferral':'我的推广',
        'AboutUs':'关于我们',
        'Help':'帮助',
        'Logout':'退出登录',
        'logoutTip':'是否确定退出登录?',
        'PlayMethod':'玩法',
        'state':'状态',
        'date':'时间',
        'serch':'查询',
        'BetAmount':'投注金额',
        'currency':'币种',
        'ProfitLoss':'盈亏',
        'blockheight':'区块高度',
        'verification':'验证',
        'All':'全部',
        'Winprize':'已中奖',
        'NoWinprize':'未中奖',
        'Bet':'投注',
        'Rebate':'返佣',
        'extension':'推广返佣',
        'Sign':'签到',
        'editPassword':'修改密码',
        'EditLoginPassword':'修改登录密码',
        'passwordTip':'密码长度6-12位，可由数字、字母组成',
        'passwordC':'请输入当前密码',
        'passwordN':'请输入新密码',
        'passwordO':'请再次输入新密码',
        'ConfirmEdit':'确认修改',
        'inconsistent':'两次密码不一致',
        'passwordLeng612':'密码长度为6-12位',
        'editSuccess':'修改成功',
        'type':'类型',
        'Award':'派奖',
        'About':'关于我们',
        'Invitation':'邀请机制',
        'InvitationD':'邀请详情',
        'TeamMembers':'团队成员',
        'ReceivedReward':'已获得奖励',
        'lowerNumber':'下级人数',
        'operation':'操作',
        'directlyunder':'直属',
        'secondlevel':'二级',
        'subordinates':'查看下级',
        // 关于
        'abouIn':'BC哈希国际娱乐集团( hash International)隶属于BC集团旗下，集团业务深耕于区块游戏研发，联合越南技术团队打造元宇宙区块游戏Axie，火爆全球，取得非凡成绩，在菲律宾政府博彩协会授权下，唯一一家获得区块链哈希竞彩牌照企业，将打造全球唯一以公平，公正，公开博彩生态，颠覆传统博彩,将传统博彩信任机制汇入区块链，做到无法造假，透明可查，开创新型博彩新元年，BC集团与您携手共创卓越，共鉴行业创世辉煌! ',
        'about1-1':'极简快速',
        'about1-2':'我们追求极简流程／快速派彩',
        'about1-3':'只需一个【去中心化】数字钱包，即刻参与抽奖',
        'about1-4':'无任何玩法、策略、设备限制，随时随地随心所欲',
        'about1-5':'中奖后10秒内自动到账，游戏不间断，体验酣畅淋漓',
        'about2-1':'我们致力保障客户信息财产安全',
        'about2-2':'无冻卡风险，无需充值存款',
        'about2-3':'无需提款申请审核',
        'about2-4':'无打款流水限制',
        'about2-5':'平台资金池随时可查，无需担心平台资金断裂',
        'about3-1':'我们捍卫每个人享有公平的权力',
        'about3-2':'区块哈希值依托于去中心化的区块链网络随机生成，任何人随时随地都可以在区块链上查询',
        'about3-3':'真正做到平台不可操控数据，所有记录透明有迹可查',
        // 帮助中心
        'HelpCenter':'帮助中心',
        'commonProblem':'常见问题',
        'helpT1':"Q1：什么是区块哈希值(Block hash)？",
        'helpM1':"区块哈希值是一段数据的DNA，每个区块哈希值都是唯一的、随机的且不可破解的。",
        'helpL1':"点击查看哈希值介绍",
        'helpT2':"Q2：什么是区块链？",
        'helpM2':"区块链从本质上讲，它是一个共享数据库，存储于其中的数据或信息。具有不可伪造、全程留痕、可以追溯、公开透明、集体维护等特征。",
        'helpL2':"点击查看百度百科区块链介绍",
        'helpT3':"Q3：中心化钱包？去中心化钱包？为什么中心化钱包无法查询转账？",
        'helpM3':"举一个例子：您自己的钱包地址是A，您给地址E转入10个USDT，但您用交易哈希值去查询，只能查询到服务商随机某个账本地址B（或C或D等等）与E的转账。同理E也无法得知这10个USDT是否是您转出的，因为E只能看见是账本地址B给他转入10个USDT。这就是为什么中心化钱包无法在区块链上查询真实转账信息。",
        'helpL3':"点击查看区块链钱包区别",
        'helpT4':"Q4：为什么我会连续中奖或不中奖？",
        'helpM4':"中奖与不中奖是随机事件，随机事件的发生的频率会趋于一个稳定值。在非迭代的情况下，事物出现的概率对每个人来讲是相等的，这个情况下，每个人都是公平的。同时随机事件会遵大数定律（在试验不变的条件下，重复试验多次，随机事件的频率近似于它的概率大数定律定义），在转账频次不断增加的情况下，中奖与不中奖的概率会趋于一个稳定值。所以请您继续转账，随着转账频次增加则中奖总概率会提升。",
   
        'helpT5':"Q5：为什么会延迟到帐？",
        'helpM5':"由于区块链技术为了保证资金的安全性，会进行多次核算，比对，确认。所以计算速度较慢，但是贵在安全。",
        'helpL5':"点击查看区块链的速度困境：《贵在信任，慢得其所》",
        'helpT6':"Q6：为什么转币时有那么多USDT的类型选项？我该选哪一个？",
        'helpM6':"全球有许多知名公链都支持进行USDT转账，而幸运哈希是基于波场TRC20技术。所以幸运哈希的转账地址是属于USDT-TRC20， 您应该选择USDT-TRC20；如果您选择了其他类型，幸运哈希则无法收到您的USDT，这会造成您和幸运哈希双方的损失。",
        'helpL6':"点击查看全球知名公链简介",
        'helpT7':"Q7：转币时转错地址或者选错币的类型，怎么办？是否还能找回？",
        'helpM7':"比如说您给我们转币时应该选择USDT-TRC20类型，但您选择了其他类型USDT-OMNI或者USDT-ERC20，那么就会出现丢币的情况，这种情况是无法找回的。",
    }
};
