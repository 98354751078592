<template>
    <div class="details">
      <div class="hear">
        <h4>交易详情</h4>
       
      </div>
  
      <div class="table">
        <div class="tr h-tr">
         <div class="td h-td flex-3">订单编号</div>
          <div class="td h-td flex-3">交易时间</div>
          <div class="td h-td flex-3">交易金额(元)</div>
         
        </div>
        <div class="lists">
          <div
            class="tr b-tr"
            v-for="(item, index) in orderList"
            :key="index"
          >
            <div class="td b-td flex-3">{{ item.orderNo }}</div>
            <div class="td b-td flex-4">
              {{ filterTime(item.createTime, "time") }}
            </div>
            
            <div class="td b-td flex-2">{{ item.totalMoney/1000.00 }}</div>
          </div>
        </div>
      </div>
      <div class="fool">
        <div class="">
          总计交易：<span class="red">{{ subTotal/1000.00 }}元</span>
        </div>
        <!-- <div class="">
          应得收益：<span class="red">{{ divideFee/1000.00 }}元</span>
        </div> -->
      </div> 
      <!-- <div class="btn">
        <van-button
          color="#f7d568"
          v-if="this.id == null"
          size="mini"
          @click="comfirmCheck"
          >生成对账单</van-button
        >
        <van-button
          plain
          color="#000"
          v-if="this.id != null"
          size="mini"
          @click="goBack"
          >账单已生存</van-button
        >
      </div> -->
      <div class="home" @click="goHome">
        <img src="@/assets/images/return.png" />
      </div>
    </div>
  </template>
    <script>
  import API from "@/api/api_shop.js";
  export default {
    name: "transactionAmount",
    data() {
      return {
        id: "",
        info: {},
        subTotal: 0,
        divideFee: 0,
        minDate: new Date(2010, 0, 1),
        maxDate: new Date(2010, 0, 1),
        startDate: "",
        endDate: "",
        orderList: [],
        
      };
    },
    created() {
    
      this.getOrderByShop();
    
    
      
    },
    methods: {
      goHome() {
        location.href = "/shopCenter/index";
      },
     
    
  
      TowTimeDayFormat(time1, time2) {
        let totalDays, diffDate;
        var day1 = Date.parse(time1);
        var day2 = Date.parse(time2);
        // 将两个日期都转换为毫秒格式，然后做差
        diffDate = Math.abs(day1 - day2); // 取相差毫秒数的绝对值
        totalDays = Math.floor(diffDate / (1000 * 3600 * 24)); // 向下取整
        return totalDays; // 相差的天数
      },
      
      filterTime(time, type) {
        let str = "";
        let date = new Date(time);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let minute = date.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        if (type == "time") str = `${y}-${m}-${d}  ${h}:${minute}:${s}`;
        if (type == "date") str = `${y}-${m}-${d}`;
        return str;
      },

      getOrderByShop(){
      
        API.getOrderByShop().then((res) => {
        if (res.code==200){
           this.orderList=res.rows;
           for(var i=0;i<this.orderList.length;i++){
            this.subTotal=(this.subTotal+this.orderList[i].totalMoney);
            //this.divideFee=(this.divideFee+this.orderList[i].divide_fee);

           }
           //console.log(res);
        }
      })
      }
     
    },
  };
  </script>
    <style  lang="scss" scoped>
  .home {
    position: fixed;
    top: 3%;
    left: 5%;
    img {
      width: 20%;
      height: 20%;
    }
  }
  .hear {
    width: 100%;
    height: 150px;
    background-color: #ffcb51;
    background-image: linear-gradient(62deg, #ffcb51 0%, #f7d568 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding: 30px 0px;
  }
  .details {
    width: 100%;
    height: 100vh;
    background: #fff;
    padding: 30px;
    box-sizing: border-box;
    h2 {
      font-size: 40px;
      text-align: center;
    }
    h4 {
      font-size: 32px;
      text-align: center;
    }
    .time {
      font-size: 28px;
      color: #333;
      margin-bottom: 20px;
      text-align: center;
    }
    .table {
      .tr {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .td {
        text-align: center;
      }
      .h-tr {
        background: #e7e3e3;
        border-bottom: 1px solid #eee;
        height: 80px;
      }
      .b-tr {
        height: 40px;
        border-bottom: 1px solid #e7e3e3;
      }
      .h-td {
        font-size: 30px;
        color: #333;
        font-weight: 700;
      }
      .b-td {
        font-size: 24px;
        color: #666;
      }
      .flex-1 {
        flex: 1;
      }
      .flex-2 {
        flex: 2;
      }
      .flex-3 {
        flex: 3;
      }
  
      .flex-4 {
        flex: 4;
      }
      .lists {
        //   padding-top: 10px;
        background: #eee;
        min-height: 40vh;
        max-height: 60vh;
        overflow-y: auto;
      }
    }
    .fool {
      display: flex;
      justify-content: space-between;
      font-size: 30px;
      padding: 30px 0px;
      box-sizing: border-box;
      .red {
        color: rgb(247, 39, 39);
        font-weight: 600;
      }
    }
    .btn {
      display: flex;
      justify-content: center;
      align-content: center;
    }
  }
  </style>