<template>
  
  <div>
    <!-- 优惠券单元格 -->

    <!-- 优惠券列表 -->
    <van-coupon-list
      :coupons="coupons"
      :chosen-coupon="chosenCoupon"
      :disabled-coupons="disabledCoupons"
      :exchange-button-disabled="true"
      :show-exchange-bar="false"
      :show-close-button="false"
      enabled-title="未使用"
      disabled-title="已使用"
      @change="onChange"
    />
    <div class="overlay" id="qr" v-if="isShow" @click="showQrcode"></div>
    <div ref="canvasWrapper" id="qrimg"
    style="position: absolute;
	width: 200px;
	height: 200px;
	left: 50%;
	top: 50%;
	margin-left: -100px;
	margin-top: -100px;
" @click="showQrcode"></div>

  </div>

  
</template>
  <script>
import API from "@/api/api";
import dateToTimestamp from "@/utils/dateToTimestamp";
import QRCode from 'qrcode'
const coupon = {
  available: 1,
  condition: "无使用门槛\n最多优惠12元",
  reason: "",
  value: 150,
  name: "优惠券名称",
  startAt: 1489104000,
  endAt: 1514592000,
  valueDesc: "1.5",
  unitDesc: "元",
  couponType: 0,
  id: 0,
  canvasData:null,
};
export default {
  data() {
    return {
      closeQr:true,
      chosenCoupon: -1,
      coupons: [], // 可用优惠价
      disabledCoupons: [],
      wxCode: "", // 微信code
      appId: "wxc04364be5867ff13", // 正式
      qrcodeUrl:"",
      isShow:false,
    };
  },
  created() {
    this.wxCode = this.$route.query.code;
    var userAgentStr = window.navigator.userAgent;
    if (/MicroMessenger/.test(userAgentStr)) {
      if (!this.wxCode) {
        this.getWXcode();
      } else {
        this.wxLogin();
       
      }
    }

  },
  methods: {
    showQrcode(){
      //alert("111");
      this.isShow=false;
      this.closeQr=false;
      console.log(document.getElementById('qrimg').childNodes);
      var nodelist=document.getElementById('qrimg').childNodes;
      nodelist[0].remove();

      //document.getElementById('qrimg').removeChild(this.canvasData);
      //this.$refs.canvasWrapper.remove();
      //this.generateCode();
      //this.$refs.canvasWrapper.removeChild();
    },
    getWXcode() {
      let redirectUri = encodeURIComponent(
        // `http://192.168.1.3:5001/payment?shopId=${this.shopId}`

        `https://h5.womaidan.com/usercenter`
      );
      //去微信公众号官方授权页根据appid获取code，之后会携带code值自行返回redirect_uri页面
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appId +
        "&redirect_uri=" +
        redirectUri +
        "&response_type=code&scope=snsapi_base#wechat_redirect";
    },
    onChange(index){
      //alert(index);

      if(this.coupons[index].couponType==4){
        let qUrl={
          shopId:this.coupons[index].shopId,
          couponId:this.coupons[index].id
        };
        this.qrcodeUrl=JSON.stringify(qUrl);
       
        this.isShow=true;
        this.closeQr=true;
        this.generateCode();
        //alert("13333");
      }else{
        location.href="/payment?shopId="+this.coupons[index].shopId;
      }
      
    },

    generateCode() {
    QRCode.toCanvas(this.qrcodeUrl, {
      width: 200,
      margin: 1
    }, (error, canvas) => {
      if (error) {
        console.error(error)
        return
      }
      this.isShow=true;
      this.canvasData=canvas;
      //console.log(this.$refs.canvasWrapper);
      // 在canvas的父元素中插入canvas元素
      //this.$refs.canvasWrapper.appendChild(canvas)

      document.getElementById('qrimg').appendChild(this.canvasData)
      
      
    })
  },

    wxLogin() {
      let params = {
        cloudID: "",
        code: this.wxCode,
        encrypteData: "",
        iv: "",
        phone: "",
        shopId: this.shopId,
      };
      API.wxLogin(params).then((res) => {
        console.log(params);
        if (res.code == 200) {
          console.log(res.token);
          localStorage.setItem("access-token", res.token);
          this.getCoupons();
        }else if(res.code==500){
          location.href="/userCenter";
        }
      });
    },

    
    getCoupons(){
      this.coupons=[];
      API.getShopCoupon1().then((res) => {
        let data = res.data;
        console.log(data);
        const coupons = data.map((op) => {
         

          ///console.log(start);
          if(op.couponType==3){
            return {
            available: "测试",
            condition: "无使用门槛",
            description: "适用店："+op.shopName,
            value: op.couponValue * 100,
            name: op.couponCode,
            startAt: new Date(Date.parse(op.couponStartTime)).getTime(),
            endAt: new Date(Date.parse(op.couponEndTime)).getTime(),
            couponType:op.couponType,
            valueDesc: op.couponValue,
            unitDesc: "元",
            id:op.id,
            state:op.state,
            couponFullValue:op.couponFullValue,
            shopId:op.shopId
          };
          }else if(op.couponType==2){
            return {
            available: "测试",
            condition: op.couponFullValue==0?"无使用门槛":"满"+op.couponFullValue,
            description: "适用店："+op.shopName,
            value: op.couponValue * 100,
            name: op.couponCode,
            startAt: new Date(Date.parse(op.couponStartTime)).getTime(),
            endAt: new Date(Date.parse(op.couponEndTime)).getTime(),
            couponType:op.couponType,
            valueDesc: op.couponDiscount,
            unitDesc: "折",
            id:op.id,
            state:op.state,
            couponFullValue:op.couponFullValue,
            shopId:op.shopId
          };
          }else if(op.couponType==4){
            return {
            available: "测试",
            condition: "无使用门槛",
            description: "适用店："+op.shopName,
            value: 0,
            name: op.couponCode,
            startAt: new Date(Date.parse(op.couponStartTime)).getTime(),
            endAt: new Date(Date.parse(op.couponEndTime)).getTime(),
            couponType:op.couponType,
            valueDesc: "实物",
            unitDesc: "奖",
            id:op.id,
            state:op.state,
            couponFullValue:op.couponFullValue,
            shopId:op.shopId
          };
        }else{
            return {
            available: "测试",
            condition: op.couponFullValue==0?"无使用门槛":"满"+op.couponFullValue,
            description: "适用店："+op.shopName,
            value: op.couponValue * 100,
            name: op.couponCode,
            startAt: new Date(Date.parse(op.couponStartTime)).getTime(),
            endAt: new Date(Date.parse(op.couponEndTime)).getTime(),
            couponType:op.couponType,
            valueDesc: op.couponValue,
            unitDesc: "元",
            id:op.id,
            state:op.state,
            couponFullValue:op.couponFullValue,
            shopId:op.shopId
          };
        }
        });
        console.log(coupons);
        let no_use_coupons=coupons.filter(item => {return item.state==0});;

        let use_coupons=coupons.filter(item => {return item.state==1});
        this.coupons = no_use_coupons;
        this.disabledCoupons=use_coupons;
        //this.disabledCoupons=null;
        // this.detail = res.data;
        
      });

    },
  },
 
};
</Script>
<style lang="scss" scoped>
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
}
</style>