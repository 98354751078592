<!--
 * @Author: yhz 11231852@qq.com
 * @Date: 2023-12-22 23:31:50
 * @LastEditors: yhz 11231852@qq.com
 * @LastEditTime: 2023-12-23 01:01:27
 * @FilePath: \web-app\src\views\payment\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="payment">
    <!-- 商店名称 -->
    <div class="store-box">
      <div class="phone">
        <img
          v-if="detail.headPic"
          :src="`https://pxkj-1322384497.cos.ap-guangzhou.myqcloud.com${detail.headPic}`"
          alt=""
          srcset=""
        />
      </div>
      <div class="info">
        <p class="name">{{ detail.shopShortName }}</p>
        <p class="ranking" style="line-height: 22px">
          {{ detail.shopAddress
          }}<span style="margin-left: 10px; float: right" @click="callPhone"
            ><img src="@/assets/images/dianhua.png" width="25" /></span
          ><span style="margin-left: 10px; float: right" @click="goNav"
            ><img src="@/assets/images/dh.png" width="25"
          /></span>
        </p>
        <div class="recommend">
          <div>推荐指数：</div>
          <van-rate
            readonly
            :size="18"
            color="#FF6619"
            void-color="#CCCCCC"
            v-model="rateNum"
          />
        </div>
      </div>
    </div>
    <!-- 应付金额 -->
    <div class="payableAmount">
      <div class="tit">应付金额</div>
      <div class="inp-box">
        <div class="icon">￥</div>
        <div class="inp">
          <van-field
            v-model="amount"
            type="number"
            placeholder="请输入金额"
            label=""
            @change="getShopCoupon"
          />
        </div>
      </div>
      <div class="tip">
        {{ detail.tip == null ? "付款成功可获取更多奖励" : detail.tip }}
      </div>
    </div>
    <!-- 选择支付券 -->
    <div class="coupon-box">
      <van-coupon-cell
        :coupons="coupons"
        :chosen-coupon="chosenCoupon"
        @click="getCouponsList"
      />
      <!-- 优惠券列表 -->
      <van-popup
        v-model="showCouponList"
        round
        position="bottom"
        style="height: 90%; padding-top: 4px"
      >
        <van-coupon-list
          :coupons="coupons"
          :chosen-coupon="chosenCoupon"
          :disabled-coupons="disabledCoupons"
          @change="onChange"
          @exchange="onExchange"
        />
      </van-popup>
      <van-cell title="实际支付" :value="actualPayment" />
    </div>
    <!-- 按钮 -->
    <div class="btn">
      <van-button
        @click="pay"
        type="primary"
        round
        block
        :disabled="amount && amount > 0 ? false : true"
        :color="amount && amount > 0 ? '#FF6619' : '#FDB089'"
        >确认付款</van-button
      >
    </div>

    <!-- 广告 -->
    <!-- <div class="notice">
      <img src="@/assets/images/notice.png" alt="" srcset="" />
    </div> -->

    <!-- 抽奖弹框 -->
    <van-popup v-model="showPrize" round>
      <!-- 转盘抽奖 -->
      <div class="prize-box">
        <div class="prize-content">
          <!-- 转盘的背景盒子，JS填充奖品背景 -->
          <div class="prize-list" id="prize-list"></div>

          <!-- 转盘的奖品盒子，JS填充奖品 -->
          <div class="prize-reward" id="prize-reward">
            <!-- 奖项渲染 -->
            <!-- <div
              class="prize-item"
              data-id="1" style="transform: rotate(45deg) translate(-50%, 44px);
                color: #f56134;width: 80px;">
              <div class="prize-name">测试奖品1</div>
              <div class="prize-icon">
                <img src="undefined" />
              </div>
            </div> -->
          </div>
        </div>
        <!-- 点击抽奖按钮 -->
        <div class="prize-button" id="prize-button" @click="doActivePrize">
          <img src="@/assets/images/kaishichoujiang.png" alt="" />
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
import API from "@/api/api";
import $ from "jquery";
import dySdk from "@/assets/js/douyin_open.umd";
import dateToTimestamp from "@/utils/dateToTimestamp";
const coupon = {
  available: 1,
  discount: 0,
  denominations: 150,
  origin_condition: 0,
  reason: "",
  value: 150,
  name: "优惠券名称",
  start_at: 1489104000,
  end_at: 1514592000,
};
export default {
  name: "home",
  data() {
    return {
      chosenCoupon: -1,
      coupons: [], // 可用优惠价
      disabledCoupons: [coupon], // 不可用优惠价
      showCouponList: false,
      rateNum: 5, // 推荐指数
      detail: {}, // 商店详情
      remark: "", // 备注
      amount: null, // 支付金额
      shopId: "", // 商店id
      wxCode: "", // 微信code
      appId: "wxc04364be5867ff13", // 正式
      // appId: "wx19a143258f886d23", // 测试
      tip: "付款成功可获取更多奖励d",
      sortList: [], // 排序
      activeId: "", // 活动id
      showPrize: false, // 显示抽奖
      awardsList: [], // 奖项数据
      discountAmount: 0, // 优惠金额
      authCode: "", //支付宝code
      dyCode: "", //抖音code
    };
  },
  computed: {
    actualPayment() {
      console.log(Math.round(this.amount * 100));
      console.log(this.discountAmount * 100);
      const result =
        (Math.round(this.amount * 100) - this.discountAmount * 100) / 100;
      //this.amount=result
      console.log(result);
      return result;
    },
  },
  created() {
    this.shopId = this.$route.query.shopId;
    if (this.shopId != 0) {
      localStorage.setItem("shopId", this.shopId);
    }
    this.wxCode = this.$route.query.code;
    this.authCode = this.$route.query.auth_code;
    this.dyCode = this.$route.query.code;
    var userAgentStr = window.navigator.userAgent;
    //alert(userAgentStr);
    if (/MicroMessenger/.test(userAgentStr)) {
      console.log("微信");
      if (!this.wxCode) {
        this.getWXcode();
      } else {
        this.wxLogin();
      }
    } else if (/AlipayClient/.test(userAgentStr)) {
      // if(!this.$route.query.auth_code){

      //alert(this.authCode);
      if (!this.authCode) {
        this.getAliCode();
      } else {
        //alert(this.authCode);
        this.aliLogin();
      }

      //}
      //this.shopId=localStorage.getItem("shopId");
      //alert(this.shopId);
    } else if (/aweme/.test(userAgentStr)) {
      //alert("抖音");
      // if(!this.dyCode){
      //   this.getDyCode();
      // }else{
      //   this.dyLogin();
      // }
      this.getDyCode();
    }
    //this.getShopCoupon();
  },
  mounted() {
    this.getShopDetailById();
  },
  methods: {
    goNav() {
      this.$wx.ready(() => {
        this.$wx.openLocation({
        type: "gcj02",
        latitude: parseFloat(this.detail.lng),
        longitude: parseFloat(this.detail.lat),
        name: this.detail.shopShortName,
        scale: 18,
        address: this.detail.shopAddress,
      });
      });
     
    },
    callPhone() {
      window.location.href = "tel://" + this.detail.linkPhone;
    },
    onChange(index) {
      if (
        this.coupons[index].couponType == 1 ||
        this.coupons[index].couponType == 3
      ) {
        this.discountAmount = this.coupons[index].valueDesc;
        console.log(this.discountAmount);
      } else if (this.coupons[index].couponType == 2) {
        if (this.amount * 100 > 1) {
          console.log(index);
          this.discountAmount =
            this.amount * ((10 - this.coupons[index].couponDiscount) / 10);
          this.coupons[index].value = this.discountAmount * 100;
          console.log(this.discountAmount);
        }
      }

      this.showCouponList = false;
      this.chosenCoupon = index;
      console.log(this.coupons);
    },
    onExchange(code) {
      this.coupons.push(coupon);
    },
    getCouponsList() {
      this.showCouponList = true;
      this.getShopCoupon();
    },
    // 获取优惠券
    getShopCoupon() {
      // if (!this.amount) {
      //   this.$toast(`请先输入支付金额`);
      //   return false;
      // }
      //this.showCouponList = true;
      //console.log(this.coupons);
      if (this.amount > 0) {
        // this.coupons.forEach((c,index) => {
        //   console.log(c);
        //   if(c.couponType==3){

        //     if((this.amount*100)<c.value){

        //       this.coupons.splice(index,1);
        //     }
        //   }
        // });
        // this.coupons=[];
        // this.getCoupons();
        //this.disabledCoupons.length=0;
        //this.getShopDetailById();
        this.coupons = [];
        API.getShopCoupon(this.shopId).then((res) => {
          let data = res.data;
          console.log(data);
          const coupons = data.map((op) => {
            if (op.couponType == 3) {
              return {
                available: "测试",
                condition: "无使用门槛",
                reason: "",
                value: op.couponValue * 100,
                name: op.couponCode,
                startAt: dateToTimestamp(op.couponStartTime),
                endAt: dateToTimestamp(op.couponEndTime),
                couponType: op.couponType,
                valueDesc: op.couponValue,
                unitDesc: "元",
                id: op.id,
                couponFullValue: op.couponFullValue,
              };
            } else if (op.couponType == 2) {
              return {
                available: "测试",
                condition:
                  op.couponFullValue == 0
                    ? "无使用门槛"
                    : "满" + op.couponFullValue,
                reason: "",
                value: op.couponValue * 100,
                name: op.couponCode,
                startAt: dateToTimestamp(op.couponStartTime),
                endAt: dateToTimestamp(op.couponEndTime),
                couponType: op.couponType,
                valueDesc: op.couponDiscount,
                couponDiscount: op.couponDiscount,
                unitDesc: "折",
                id: op.id,
                couponFullValue: op.couponFullValue,
              };
            } else if (op.couponType == 4) {
              return {
                available: "测试",
                condition: "无使用门槛",
                reason: "",
                value: 0,
                name: op.couponCode,
                startAt: dateToTimestamp(op.couponStartTime),
                endAt: dateToTimestamp(op.couponEndTime),
                couponType: op.couponType,
                valueDesc: "实物",
                unitDesc: "奖",
                id: op.id,
                couponFullValue: op.couponFullValue,
              };
            } else if (op.couponType == 1) {
              return {
                available: "测试",
                condition:
                  op.couponFullValue == 0
                    ? "无使用门槛"
                    : "满" + op.couponFullValue,
                reason: "",
                value: op.couponValue * 100,
                name: op.couponCode,
                startAt: dateToTimestamp(op.couponStartTime),
                endAt: dateToTimestamp(op.couponEndTime),
                couponType: op.couponType,
                valueDesc: op.couponValue,
                unitDesc: "元",
                id: op.id,
                couponFullValue: op.couponFullValue,
              };
            }
          });

          this.coupons = coupons;
          this.disabledCoupons.length = 0;
          //this.disabledCoupons=null;
          // this.detail = res.data;
          for (var i = this.coupons.length - 1; i >= 0; i--) {
            if (this.coupons[i].couponType == 3) {
              if (this.coupons[i].value > this.amount * 100) {
                this.disabledCoupons.push(this.coupons[i]);
                this.coupons.splice(i, 1);
              }
            } else if (
              this.coupons[i].couponType == 2 &&
              this.coupons[i].couponFullValue > this.amount
            ) {
              this.disabledCoupons.push(this.coupons[i]);
              this.coupons.splice(i, 1);
            } else if (
              this.coupons[i].couponType == 1 &&
              this.coupons[i].couponFullValue > this.amount
            ) {
              this.disabledCoupons.push(this.coupons[i]);
              this.coupons.splice(i, 1);
            } else if (this.coupons[i].couponType == 4) {
              this.disabledCoupons.push(this.coupons[i]);
              this.coupons.splice(i, 1);
            }
          }
        });
      } else {
        this.coupons = null;
      }
    },

    // 商铺详情
    getShopDetailById() {
      API.getShopDetailById(this.shopId).then((res) => {
        if (res.code == 200) {
          this.detail = res.data;
          this.setSort(res.data);
          this.$wx.ready(() => {
            //alert("1111");
            var timestamp = new Date().getTime();
            this.$wx.updateTimelineShareData({
              title:
                this.detail.shareTitle == null
                  ? this.detail.shopShortName
                  : this.detail.shareTitle, // 分享标题
              desc: this.detail.shopShortName, // 分享描述
              link: encodeURI(
                "https://h5.womaidan.com/wxshare?shopId=" +
                  this.shopId +
                  "&t=" +
                  timestamp +
                  "&from=share"
              ), // 分享链接
              imgUrl: "https://pxkj.file.ld-top.com/" + this.detail.shopPic, // 分享图标
              type: "video", // 分享类型,music、video或link，不填默认为link
              dataUrl:
                "https://pxkj.files.ld-top.com//uploadfile/1704653214331-528503.mp4",
              success: function () {
                // 用户确认分享后执行的回调函数
              },
              cancel: function () {
                // 用户取消分享后执行的回调函数
              },
            });
          });
        }
      });
      //this.getCoupons();+
    },
    getCoupons() {
      this.coupons = [];
      API.getShopCoupon(this.shopId).then((res) => {
        let data = res.data;
        console.log(data);
        const coupons = data.map((op) => {
          if (op.couponType == 3) {
            return {
              available: "测试",
              condition: "无使用门槛",
              //reason: "123123213213",
              value: op.couponValue * 100,
              name: op.couponCode,
              startAt: dateToTimestamp(op.couponStartTime),
              endAt: dateToTimestamp(op.couponEndTime),
              couponType: op.couponType,
              valueDesc: op.couponValue,
              unitDesc: "元",
              id: op.id,
              couponFullValue: op.couponFullValue,
            };
          } else if (op.couponType == 2) {
            return {
              available: "测试",
              condition:
                op.couponFullValue == 0
                  ? "无使用门槛"
                  : "满" + op.couponFullValue,
              reason: "123123213213",
              value: op.couponValue * 100,
              name: op.couponCode,
              startAt: dateToTimestamp(op.couponStartTime),
              endAt: dateToTimestamp(op.couponEndTime),
              couponType: op.couponType,
              valueDesc: op.couponDiscount,
              unitDesc: "折",
              id: op.id,
              couponFullValue: op.couponFullValue,
            };
          } else if (op.couponType == 4) {
            return {
              available: "测试",
              condition: "无使用门槛",
              reason: "123123213213",
              value: 0,
              name: op.couponCode,
              startAt: dateToTimestamp(op.couponStartTime),
              endAt: dateToTimestamp(op.couponEndTime),
              couponType: op.couponType,
              valueDesc: "实物",
              unitDesc: "奖",
              id: op.id,
              couponFullValue: op.couponFullValue,
            };
          } else if (op.couponType == 1) {
            return {
              available: "测试",
              condition:
                op.couponFullValue == 0
                  ? "无使用门槛"
                  : "满" + op.couponFullValue,
              reason: "123123213213",
              value: op.couponValue * 100,
              name: op.couponCode,
              startAt: dateToTimestamp(op.couponStartTime),
              endAt: dateToTimestamp(op.couponEndTime),
              couponType: op.couponType,
              valueDesc: op.couponValue,
              unitDesc: "元",
              id: op.id,
              couponFullValue: op.couponFullValue,
            };
          }
        });

        this.coupons = coupons;
        this.disabledCoupons.length = 0;
        //this.disabledCoupons=null;
        // this.detail = res.data;
      });
    },
    // 排序设置
    setSort(detail) {
      let obj = {
        sortActive: detail.sortActive,
        sortPay: detail.sortPay,
        sortShare: detail.sortShare,
      };
      let list = [];
      for (let key in obj) {
        let newObj = {};
        (newObj["val"] = obj[key]), (newObj["type"] = key), list.push(newObj);
      }
      this.sortList = list.sort((a, b) => a.val - b.val);
      if (this.sortList[0].type == "sortActive" && this.sortList[0].val > 0) {
        // 排序第一为抽奖时
        this.getShopActive();
      }
    },
    // 获取商家活动
    getShopActive() {
      API.getShopActive(this.shopId).then((res) => {
        if (res.code == 200) {
          let data = res.data;
          this.getActivePrize(data.id);
          this.activeId = data.id;
          this.showPrize = true;
        }
      });
    },
    // 获取奖项
    getActivePrize(activeId) {
      API.getActivePrize(activeId).then((res) => {
        if (res.code == 200) {
          let obj = {
            activeId: 1,
            couponId: 10,
            couponName: "谢谢参与",
            createBy: "1",
            createTime: "2024-01-07 23:18:33",
            id: 999,
            state: 0,
            ids: null,
            params: {},
            prizeGetNum: 1,
            prizeName: "谢谢参与",
            prizeNum: 1,
            remark: null,
            searchValue: null,
            updateBy: "18573780510",
            updateTime: "2024-01-06 14:56:11",
          };
          let awardsList = res.data;
          awardsList.push(obj);
          this.awardsList = awardsList;
          this.prize();
        }
      });
    },

    // 获取微信code
    getWXcode() {
      let redirectUri = encodeURIComponent(
        // `http://192.168.1.3:5001/payment?shopId=${this.shopId}`

        `https://h5.womaidan.com/payment?shopId=${this.shopId}`
      );
      //去微信公众号官方授权页根据appid获取code，之后会携带code值自行返回redirect_uri页面
      window.location.href =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        this.appId +
        "&redirect_uri=" +
        redirectUri +
        "&response_type=code&scope=snsapi_base#wechat_redirect";
    },

    getDyCode() {
      var url = encodeURIComponent(location.href);
      //alert(url);
      API.dyConfig(url).then((res) => {
        if (res.code == 200) {
          dySdk.config({
            params: {
              client_key: res.client_key, // clientKey在你的网页应用申请通过后得到
              signature: res.signature, // 服务端计算的签名，该签名被抖音开放平台验证通过后方可调用jsb方法
              timestamp: String(res.timestamp), // 时间戳必须是字符串
              nonce_str: res.nonce_str, //生成签名用的随机字符串
              url: res.url, // 为应用申请的 JSB 安全域名下的链接，需要携带协议。e.g. https://jsb.security.domain/page.html
            },
          });
          //alert(res.signature);
          dySdk.error((res) => {
            //alert(JSON.stringify(res));
          });
          dySdk.ready(() => {
            // Config Ready回调
            dySdk.showOpenAuth({
              params: {
                client_key: res.client_key,
                scopeList: ["data.external.item"], // clientKey 在你的网页应用申请通过后得到
                scopes: {
                  //此处配置想要获取的用户权限
                  user_info: 0, // 0: 必选；1: 可选，默认不选中； 2: 可选，默认选中
                  "data.external.item": 2,
                },
                response_type: "code", // 默认填‘code’
              },
              success: (rescode) => {
                this.dyCode = rescode.ticket;
                if (this.dyCode) {
                  this.dyLogin();
                }

                //alert(JSON.stringify(rescode));
                //拿到返回值后， res.response.ticket相当于微信中的code；
                //调用接口把ticket传给后台，后台拿着这个ticket调用抖音api获取用户信息
              },
              error: (err) => {
                alert(JSON.stringify(err));
              },
            });
          });
        }
      });

      // let params = {
      //   client_key: "aw2h9xjngnn0tklc",
      //   response_type: "code",
      //   scope: "user_info",
      //   redirect_uri: url,
      // };
      //location.href="https://open.douyin.com/platform/oauth/connect?client_key=aw2h9xjngnn0tklc&redirect_uri=https://h5.womaidan.com/&response_type=code&scope=user_info&state=1662039377758420993";

      // API.dyCode(params).then((res) => {

      //     alert(res);
      // });

      //location.href = "https://open.douyin.com/platform/oauth/connect?client_key=&response_type=code&scope=user_info&redirect_uri="+url;
    },
    getAliCode() {
      var url = encodeURIComponent(location.href);
      location.href =
        "https://openauth.alipay.com/oauth2/publicAppAuthorize.htm?app_id=2021004136628332&scope=auth_base&redirect_uri=" +
        url;
    },
    // 微信授权登录
    wxLogin() {
      let params = {
        cloudID: "",
        code: this.wxCode,
        encrypteData: "",
        iv: "",
        phone: "",
        shopId: this.shopId,
      };
     
        API.wxLogin(params).then((res) => {
        console.log(params);
        if (res.code == 200) {
          console.log(res.token);
          localStorage.setItem("access-token", res.token);
          this.getWxConfig();
        } else if(res.code==500) {
          location.href="/payment?shopId="+this.shopId;
        }
      });
      
      
    },
    dyLogin() {
      let params = {
        code: this.dyCode,
        shopId: this.shopId,
      };
      API.dyLogin(params).then((res) => {
        if (res.code == 200) {
          //lert(res.token);
          localStorage.setItem("access-token", res.token);
          //this.getWxConfig();
        }
      });
    },
    aliLogin() {
      let params = {
        authCode: this.authCode,
        shopId: this.shopId,
      };
      API.aliLogin(params).then((res) => {
        console.log(params);
        //alert(res.code);
        if (res.code == 200) {
          //lert(res.token);
          localStorage.setItem("access-token", res.token);
          //this.getWxConfig();
        }
      });
    },
    getWxConfig() {
      let curl = location.href.split("#")[0];
      //console.log(url);
      let param = {
        url: curl,
        client: "JSAPI",
      };
      API.wxConfig(param).then((res) => {
        console.log(res);
        if (res.code == 200) {
          this.$wx.config({
            debug: false, // 开发阶段设为true，生产阶段设为false
            appId: res.appId,
            timestamp: res.timestamp,
            nonceStr: res.nonceStr,
            signature: res.signature,
            jsApiList: [
              "chooseWXPay",
              "onMenuShareTimeline",
              "updateTimelineShareData",
              "updateAppMessageShareData",
              "getLocation",
              "openLocation",
            ], // 需要使用的API列表
          });
        }
      });
      this.$wx.ready(() => {
        this.$wx.getLocation({
          type: "gcj02", // 默认为wgs84的 gps 坐标，如果要返回直接给 openLocation 用的火星坐标，可传入'gcj02'
          success: function (res) {
            console.log(res);
          },
        });
      });
    },
    // 创建支付订单
    pay() {
      console.log(this.coupons);

      let couponid = "";
      if (this.chosenCoupon != -1) {
        couponid = this.coupons[this.chosenCoupon].id;
      }
      console.log(this.amount * 100);
      let params = {
        shopId: this.shopId,
        amount: parseInt(this.actualPayment * 100),
        couponId: couponid,
        remark: this.remark,
      };
      console.log(params);

      if (!this.wxCode) {
        //alert("111");
        API.aliPay(params).then((res) => {
          if (res.code == 200) {
            //alert("1111");
            document.write(res.data);
            //location.href="/paymentCompled?shopId="+this.shopId;
          }
        });
        //this.$toast(`该平台下支付正在开发中..`);
        //return;
      } else {
        API.createwxPay(params).then((res) => {
          if (res.code == 200) {
            let timestr = res.data.timeStamp;
            // let pa={
            //     appId: res.data.appId,
            //     timeStamp: timestr, // 时间戳，从后台接口返回
            //     nonceStr: res.data.nonceStr, // 随机字符串，从后台接口返回
            //     package: res.data.packageVal, // 统一下单接口返回的 prepay_id 参数值，必填
            //     signType: 'RSA', // 签名算法类型，默认为 MD5
            //     paySign: res.data.paySign // 签名，从后
            // };
            // console.log(pa);
            this.$wx.ready(() => {
              this.$wx.chooseWXPay({
                appId: res.data.appId,
                timestamp: res.data.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
                nonceStr: res.data.nonceStr, // 支付签名随机串，不长于 32 位
                package: res.data.packageVal, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=***）
                signType: res.data.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
                paySign: res.data.paySign, // 支付签名
                success: function (res) {
                  // 支付成功后的回调函数
                  this.$toast(`支付成功`);
                  location.href = "/paymentCompled?shopid=" + this.shopId;
                  //location.href = window.location.origin + '/#/order/1';
                },
                cancel: function (res) {
                  // 支付失败后的回调函数
                  this.$toast(`支付失败`);
                  location.href = "/payment?shopid=" + this.shopId;
                  //location.href = window.location.origin + '/#/order/0';
                },
              });
            });
          }
          // this.detail = res.data;
        });
      }
    },
    // // 支付
    // wxPay() {
    //   API.wxPay().then((res) => {
    //     this.$toast("支付成功");
    //   });
    // },

    // 渲染抽奖
    prize(data) {
      let reward = "";
      let count = this.awardsList.length;
      const zhuanpanImg = require(`@/assets/images/zhuanpan_${count}.png`);
      let rotate = 360 / count;
      let itemRotate = 360 / 2 / count;
      $(".prize-box .prize-list").css({
        "background-image": `url('${zhuanpanImg}')`,
        transform: `rotate(${itemRotate}deg)`,
      });
      // 设置数量奖品的旋转角度值
      let width = 100;
      // 组装背景和奖品的标签，且设置奖品的旋转角度
      for (let i = 0; i < count; i++) {
        // list += '<div class="prize-li"></div>';
        reward +=
          '<div class="prize-item" data-id="' +
          this.awardsList[i]["id"] +
          '" style="transform: rotate(' +
          (rotate * (i + 1) - itemRotate) +
          "deg) translate(-50%,20px);color: #F56134;width: " +
          width +
          'px;">\n' +
          '                                <div class="prize-name">\n' +
          this.awardsList[i]["prizeName"] +
          "                                </div>\n" +
          "                            </div>";
      }
      // 填充内容
      $("#prize-reward").html(reward);
    },
    // 点击抽奖
    doActivePrize() {
      API.doActivePrize(this.activeId).then((res) => {
        if (res.code == 200) {
          // 转盘
          let data = res.data;
          console.log(data);
          if (data != null) {
            // 中奖
            this.turn(data);
          } else {
            let obj = {
              activeId: 1,
              couponId: 10,
              couponName: "谢谢参与",
              createBy: "1",
              createTime: "2024-01-07 23:18:33",
              id: 999,
              state: 0,
              ids: null,
              params: {},
              prizeGetNum: 0,
              prizeName: "谢谢参与",
              prizeNum: 1,
              remark: null,
              searchValue: null,
              updateBy: "18573780510",
              updateTime: "2024-01-06 14:56:11",
            };
            this.turn(obj);
          }
        }
      });
    },
    // 中奖或者未中奖，转盘转动
    turn(data) {
      let baseNum = 360 / this.awardsList.length;
      for (let i = 0; i < this.awardsList.length; i++) {
        let e = this.awardsList[i];
        if (e.id == data.id) {
          // + RandomNumBoth(5,baseNum-5)
          var degNum = 3600 - (i + 1) * baseNum + baseNum / 2;
          $(".prize-content").css({
            transition: "transform 6s cubic-bezier(0.25, 0.1, 0.01, 1)",
            transform: "rotate(" + degNum + "deg)",
          });
          setTimeout(() => {
            this.prizeHide(data);
          }, 6500);
        }
      }
    },
    // 抽奖隐藏
    prizeHide(data) {
      if (data.prizeGetNum !=0) {
        this.$toast(`恭喜您，获得${data.couponName}`);
      } else {
        this.$toast(`很遗憾，谢谢参与`);
      }
      this.showPrize = false;
    },
    // 转盘复位
    reset() {
      $(".prize-content").css({ transition: "", transform: "rotate(0deg)" });
    },
  },
};
</script>
<style lang="scss" scoped>
.payment {
  width: 100%;
  height: 100vh;
  background: #fafafa;
  padding: 30px 30px 200px 30px;
  box-sizing: border-box;

  .store-box {
    display: flex;
    width: 100%;
    background: #ffffff;
    border-radius: 16px;
    margin-bottom: 32px;
    padding: 24px;
    box-sizing: border-box;

    .phone {
      width: 192px;
      height: 192px;
      margin-right: 24px;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .info {
      font-size: 24px;
      color: #666666;
      padding-top: 10px;
      box-sizing: border-box;

      .name {
        font-size: 32px;
        font-weight: 500;
        color: #333333;
        margin-bottom: 16px;
      }

      .ranking,
      .score {
        margin-bottom: 10px;
      }

      .recommend {
        display: flex;
        align-items: center;
      }
    }
  }

  .payableAmount {
    width: 100%;
    height: 288px;
    background: #ffffff;
    border-radius: 16px;
    padding: 24px 32px;
    box-sizing: border-box;
    margin-bottom: 10px;

    .tit {
      color: #333333;
      font-size: 28px;
      font-weight: 400;
    }

    .inp-box {
      display: flex;
      align-items: center;

      .icon {
        font-size: 48px;
        color: #000000;
      }

      .inp {
        width: 100%;
        height: 96px;
        border-bottom: 1px solid #f0f0f0;

        ::v-deep .van-field__control {
          color: 333;
          font-size: 38px;

          &::-webkit-input-placeholder {
            color: #ccc;
            font-size: 38px;
          }
        }
      }
    }
  }

  .coupon-box {
    padding: 10px 0px;
    margin-bottom: 10px;

    ::v-deep(.van-coupon-list__exchange-bar) {
      display: none;
    }
  }

  .tip {
    font-size: 28px;
    color: #e72d2d;
    text-align: center;
    padding-top: 32px;
    box-sizing: border-box;
    font-weight: 600;
  }

  .notice {
    width: 100%;
    height: 172px;
    border-radius: 16px;
    position: fixed;
    left: 0;
    bottom: 30px;
    padding: 0 30px;
    box-sizing: border-box;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

/* 翻牌抽奖 */
@keyframes flipAnimate {
  0% {
    transform: rotateY(0deg) translateZ(-10px);
    -webkit-transform: rotateY(0deg) translateZ(-10px);
    -moz-transform: rotateY(0deg) translateZ(-10px);
    -ms-transform: rotateY(0deg) translateZ(-10px);
    -o-transform: rotateY(0deg) translateZ(-10px);
  }

  30% {
    transform: rotateY(540deg) translateZ(-10px);
    -webkit-transform: rotateY(540deg) translateZ(-10px);
    -moz-transform: rotateY(540deg) translateZ(-10px);
    -ms-transform: rotateY(540deg) translateZ(-10px);
    -o-transform: rotateY(540deg) translateZ(-10px);
    left: 245px;
    width: 25px;
    height: 344px;
  }

  90% {
    transform: rotateY(540deg) translateZ(-10px);
    -webkit-transform: rotateY(540deg) translateZ(-10px);
    -moz-transform: rotateY(540deg) translateZ(-10px);
    -ms-transform: rotateY(540deg) translateZ(-10px);
    -o-transform: rotateY(540deg) translateZ(-10px);
    left: 245px;
    width: 25px;
    height: 344px;
  }

  100% {
    transform: rotateY(540deg) translateZ(-10px);
    -webkit-transform: rotateY(540deg) translateZ(-10px);
    -moz-transform: rotateY(540deg) translateZ(-10px);
    -ms-transform: rotateY(540deg) translateZ(-10px);
    -o-transform: rotateY(540deg) translateZ(-10px);
    left: 255px;
    width: 230px;
    height: 324px;
  }
}

@keyframes flipLeft {
  0% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
  }

  50% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
    left: 76px;
  }

  100% {
    transform: rotateY(180deg) translateZ(1px);
    -webkit-transform: rotateY(180deg) translateZ(1px);
    -moz-transform: rotateY(180deg) translateZ(1px);
    -ms-transform: rotateY(180deg) translateZ(1px);
    -o-transform: rotateY(180deg) translateZ(1px);
    left: 76px;
  }
}

@keyframes flipRight {
  0% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
  }

  50% {
    transform: rotateY(0deg) translateZ(1px);
    -webkit-transform: rotateY(0deg) translateZ(1px);
    -moz-transform: rotateY(0deg) translateZ(1px);
    -ms-transform: rotateY(0deg) translateZ(1px);
    -o-transform: rotateY(0deg) translateZ(1px);
    left: 476px;
  }

  100% {
    transform: rotateY(180deg) translateZ(1px);
    -webkit-transform: rotateY(180deg) translateZ(1px);
    -moz-transform: rotateY(180deg) translateZ(1px);
    -ms-transform: rotateY(180deg) translateZ(1px);
    -o-transform: rotateY(180deg) translateZ(1px);
    left: 476px;
  }
}

.flopDraw-box {
  width: 740px;
  height: 538px;
  // position: absolute;
  // left: 50%;
  // top: 398px;
  // transform: translateX(-50%);
  overflow: hidden;
  background: url("@/assets/images/fanpai.png") no-repeat center;
  background-size: 100% 100%;
  // display: none;
}

.flip_wrap {
  width: 100%;
  height: 100%;
  margin: 0 auto;
  perspective: 800px;
  /*perspective属性定义3D元素距视图的距离,以像素计。该属性允许您改变 3D 元素查看 3D 元素的视图。*/
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  -ms-perspective: 800px;
  -o-perspective: 800px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 76px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  -webkit-overflow-scrolling: unset;
}

.flip {
  width: 188px;
  height: 266px;
  transition: all 1s ease;
  /*为翻牌添加过渡效果*/
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -ms-transition: all 1s ease;
  -o-transition: all 1s ease;
  transform-style: preserve-3d;
  /*子元素将保留其 3D 位置。*/
  -webkit-transform-style: preserve-3d;
  position: absolute;
}

.flip:first-of-type {
  left: 76px;
}

.flip:nth-of-type(2) {
  left: 276px;
}

.flip:last-of-type {
  left: 476px;
}

.flip.active {
  z-index: 999;
  /* transform: translateZ(100px);
    -webkit-transform: translateZ(100px);
    -moz-transform: translateZ(100px);
    -ms-transform: translateZ(100px);
    -o-transform: translateZ(100px); */

  animation-name: flipAnimate;
  -webkit-animation-name: flipAnimate;
  animation-duration: 2s;
  -webkit-animation-duration: 2s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.flip.remainingFlip {
  z-index: 99;
  /* transform:rotateY(180deg);
    -webkit-transform:rotateY(180deg);
    -moz-transform:rotateY(180deg);
    -ms-transform:rotateY(180deg);
    -o-transform:rotateY(180deg); */
}

.remainingFlip.left {
  animation-name: flipLeft;
  -webkit-animation-name: flipLeft;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.remainingFlip.right {
  animation-name: flipRight;
  -webkit-animation-name: flipRight;
  animation-duration: 1.5s;
  -webkit-animation-duration: 1.5s;
  animation-timing-function: linear;
  -webkit-animation-timing-function: linear;
  animation-fill-mode: forwards;
  -webkit-animation-fill-mode: forwards;
}

.side {
  backface-visibility: hidden;
  /*背对屏幕时隐藏*/
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -o-backface-visibility: hidden;
  width: 100%;
  height: 100%;
  position: absolute;
  /*让背面和正面重叠*/
}

.side p {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  writing-mode: vertical-lr;
  -webkit-writing-mode: vertical-lr;
  -moz-writing-mode: vertical-lr;
  -ms-writing-mode: vertical-lr;
  -o-writing-mode: vertical-lr;
  font-size: 36px;
  color: #ff9515;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
}

.remainingFlip p {
  background: rgba(0, 0, 0, 0.2);
}

.front img,
.back img {
  width: 100%;
  height: 100%;
}

.front {
  z-index: 2;
  /*让正面朝上*/
}

.back {
  transform: rotateY(180deg) translateZ(1px);
  -webkit-transform: rotateY(180deg) translateZ(1px);
  -moz-transform: rotateY(180deg) translateZ(1px);
  -ms-transform: rotateY(180deg) translateZ(1px);
  -o-transform: rotateY(180deg) translateZ(1px);
}
/* 转盘 */
::v-deep(.prize-box) {
  // position: absolute;
  width: 598px;
  height: 598px;
  // left: 50%;
  // top: 348px;
  // transform: translateX(-50%);
  border-radius: 100%;
  overflow: hidden;
  // display: none;
  .prize-content {
    width: 598px;
    height: 598px;
    border-radius: 100%;
    position: relative;
    overflow: hidden;
    .prize-list {
      width: 598px;
      height: 598px;
      background: url("@/assets/images/zhuanpan_4.png") no-repeat;
      /* transform: rotate(45deg); */
      background-size: 100% 100%;
      background-repeat: no-repeat;
      border-radius: 100%;
      position: relative;
    }
  }
  .prize-icon {
    width: 55px;
    height: 55px;
    margin: 10px auto 0px auto;
  }
  .prize-icon img {
    width: 100%;
    height: 100%;
  }

  .prize-button {
    width: 163px;
    height: 201px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 999;
  }
  /* .prize-list {
    width: 498px;
    height: 498px;
    border-radius: 100%;
    position: relative;
}
.prize-li {
    position: absolute;
    transform-origin: left top;
    left: 299px;
    top: 299px;
    width: 299px;
    height: 299px;
    box-sizing: border-box;
} */
  .prize-reward {
    width: 598px;
    height: 598px;
    border-radius: 100%;
    position: absolute;
    top: 0px;
    left: 0;
    z-index: 99;
  }
  .prize-reward .prize-item {
    width: 10px;
    height: 250px;
    position: absolute;
    top: 40px;
    z-index: 99;
    left: 50%;
    -webkit-transform: translateX(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);
    -webkit-transform-origin: 0 100%;
    -ms-transform-origin: 0 100%;
    transform-origin: 0 100%;
  }
  .prize-button img {
    width: 100%;
    height: 100%;
  }
  .prize-item .prize-name {
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    text-align: center;
    font-size: 30px;
  }
}
</style>
  