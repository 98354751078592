<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2023-12-22 21:31:31
 * @LastEditTime: 2023-12-22 21:22:18
 * @FilePath: \web-app\src\views\index.vue
-->
<template>
  <div class="home">
    <van-form @submit="onSubmit">
      <van-field
        v-model="params.shopName"
        name="shopName"
        label="商户名称"
        placeholder="商户名称"
        :rules="[{ required: true, message: '请填写商户名称' }]"
      />
      <van-field
        v-model="params.shopShortName"
        name="shopShortName"
        label="商户简称"
        placeholder="商户简称"
        :rules="[{ required: true, message: '请填写商户简称' }]"
      />
      <!-- <van-field
        v-model="params.shopDesc"
        name="shopDesc"
        label="商户说明"
        placeholder="商户说明"
        :rules="[{ required: true, message: '请填写商户说明' }]"
      /> -->
      <van-field
        v-model="params.tradeTime"
        name="tradeTime"
        label="营业时间"
        placeholder="营业时间"
        :rules="[{ required: true, message: '请填写营业时间' }]"
      />
      <!-- <van-field
        v-model="params.address"
        is-link
        readonly
        label="地区"
        placeholder="请选择所在地区"
        @click="addressShow = true"
      /> -->
      <van-popup v-model="addressShow" round position="bottom">
        <van-cascader
          v-model="cascaderValue"
          title="请选择所在地区"
          :options="options"
          :field-names="fieldNames"
          @close="addressShow = false"
          @finish="onFinish"
        />
      </van-popup>
      <!-- <van-field
        v-model="params.shopAddress"
        name="shopAddress"
        label="详细地址"
        placeholder="详细地址"
        :rules="[{ required: true, message: '请填写详细地址' }]"
      /> -->
      <!-- <van-field
        v-model="params.shopType"
        name="shopType"
        label="所属行业"
        placeholder="所属行业"
        :rules="[{ required: true, message: '请填写所属行业' }]"
      /> -->
      <van-field
        v-model="params.shopBankNo"
        name="shopBankNo"
        label="银行账号"
        placeholder="银行账号"
        :rules="[{ required: true, message: '请填写银行账号' }]"
      />
      <van-field
        v-model="params.shopBankName"
        name="shopBankName"
        label="开户银行"
        placeholder="请填写开户银行精确到支行"
        :rules="[{ required: true, message: '请填写开户银行精确到支行' }]"
      />
      <van-field
        v-model="params.linkUserName"
        name="linkUserName"
        label="法人姓名"
        placeholder="法人姓名"
        :rules="[{ required: true, message: '请填写法人姓名' }]"
      />

      <van-field
        v-model="params.linkPhone"
        name="linkPhone"
        label="联系电话"
        placeholder="联系电话"
        :rules="[{ required: true, message: '请填写联系电话' }]"
      />
      <van-field
        v-model="params.alipayAccount"
        name="alipayAccount"
        label="支付宝账户"
        placeholder="支付宝账户"
        :rules="[{ required: true, message: '请填写支付宝账户' }]"
      />
      <van-field
        v-model="params.shopBusinessNo"
        name="shopBusinessNo"
        label="统一信用代码"
        placeholder="统一信用代码"
        :rules="[{ required: true, message: '统一信用代码' }]"
      />
      <van-field name="shopBusinesPapare" label="营业执照">
        <template #input>
          <van-uploader
            v-model="shopBusinesPapare"
            :max-count="1"
            :after-read="onReadFile"
            name="shopBusinesPapare"
          />
        </template>
      </van-field>
      <van-field name="cardFrontPic" label="法人身份证正面">
        <template #input>
          <van-uploader
            v-model="cardFrontPic"
            :max-count="1"
            :after-read="onReadFile"
            name="cardFrontPic"
          />
        </template>
      </van-field>
      <van-field name="cardBackPic" label="法人身份证反面">
        <template #input>
          <van-uploader
            v-model="cardBackPic"
            :max-count="1"
            :after-read="onReadFile"
            name="cardBackPic"
          />
        </template>
      </van-field>
      <van-field name="headPic" label="门头照">
        <template #input>
          <van-uploader
            v-model="headPic"
            :max-count="1"
            :after-read="onReadFile"
            name="headPic"
          />
        </template>
      </van-field>
      <van-field name="shopPic" label="店内照">
        <template #input>
          <van-uploader
            v-model="shopPic"
            :max-count="1"
            :after-read="onReadFile"
            name="shopPic"
          />
        </template>
      </van-field>
      <!-- <van-field name="empowerPic" label="授权函照">
        <template #input>
          <van-uploader
            v-model="empowerPic"
            :max-count="1"
            :after-read="onReadFile"
            name="empowerPic"
          />
        </template>
      </van-field> -->
      <div style="margin: 16px">
        <van-button round block type="info" native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
  </div>
</template>
<script>
import API from "@/api/api";
export default {
  name: "home",
  data() {
    return {
      params: {
        username: "",
        password: "",
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    onSubmit(values) {
      console.log("submit", values);
    },
  },
};
</script>
<style lang="scss" scoped>
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  pointer-events: none;
}
</style>
