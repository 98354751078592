/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2023-12-23 00:33:25
 * @FilePath: \web-app\src\router\index.js
 */
import Vue from "vue";
import VueRouter from "vue-router";
import { Toast,Dialog } from 'vant';
import {i18n, vantLocales} from '@/assets/language/index';



vantLocales(i18n.locale)

import login from "../views/login.vue";
// import register from "../views/register.vue";
import index from "../views/index.vue";
import test from "../views/test.vue";
import callback from "../views/callback.vue";
import payment from "../views/payment/index.vue";
import paymentCompleted from "../views/paymentCompleted/index.vue";
import wxshare from "../views/wxshare/index.vue";
import usercenter from "../views/usercenter/index.vue";
import shop_login from "../views/shopCenter/login.vue";

import shop_index from "../views/shopCenter/index.vue";
import shop_note_details from "../views/shopCenter/noteDetails.vue";
import shop_order from "../views/shopCenter/order.vue";
import shop_active_list from "../views/shopCenter/activeList.vue";
import shop_file_list from "../views/shopCenter/fileList.vue";
import shop_coupon_list from "../views/shopCenter/couponList.vue";
import shop_prize_list from "../views/shopCenter/prizeList.vue";


import shop_active_add from "../views/shopCenter/activeAdd.vue";
import shop_prize_add from "../views/shopCenter/prizeAdd.vue";
import shop_file_add from "../views/shopCenter/fileAdd.vue";
import shop_coupon_add from "../views/shopCenter/couponAdd.vue";

import shop_market_data from "../views/shopCenter/MarketData.vue";



Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "payment",
    component: payment,
    meta: {
      requireAuth: true 
    }
  },{
    path: "/test",
    name: "test",
    component: test,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: "/index",
    name: "index",
    component: index,
    meta: {
      requireAuth: true,
    }
  },{
    path: "/payment",
    name: "payment",
    component: payment,
    meta: {
      requireAuth: true,
    }
  },{
    path: "/paymentCompleted",
    name: "paymentCompleted",
    component: paymentCompleted,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: "/wxshare",
    name: "wxshare",
    component: wxshare,
    meta: {
      requireAuth: true,
    }
  },
  {
    path: "/usercenter",
    name: "usercenter",
    component: usercenter,
    meta: {
      requireAuth: true,
    }
  },
  
  {
    path: "/login",
    name: "login",
    component: login,
    meta: {
      requireAuth: true,
    }
  },{
    path: "/callback",
    name: "callback",
    component: callback,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/login",
    name: "shop_login",
    component: shop_login,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/index",
    name: "shop_index",
    component: shop_index,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/noteDetails",
    name: "shop_note_details",
    component: shop_note_details,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/order",
    name: "shop_order",
    component: shop_order,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/active",
    name: "shop_active_list",
    component: shop_active_list,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/file",
    name: "shop_file_list",
    component: shop_file_list,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/coupon",
    name: "shop_coupon_list",
    component: shop_coupon_list,
    meta: {
      requireAuth: true,
    }
  },


  {
    path: "/shopCenter/prize",
    name: "shop_prize_list",
    component: shop_prize_list,
    meta: {
      requireAuth: true,
    }
  },


  {
    path: "/shopCenter/prize/add",
    name: "shop_prize_add",
    component: shop_prize_add,
    meta: {
      requireAuth: true,
    }
  },


  {
    path: "/shopCenter/active/add",
    name: "shop_active_add",
    component: shop_active_add,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/coupon/add",
    name: "shop_coupon_add",
    component: shop_coupon_add,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/file/add",
    name: "shop_file_add",
    component: shop_file_add,
    meta: {
      requireAuth: true,
    }
  },

  {
    path: "/shopCenter/market/data",
    name: "shop_market_data",
    component: shop_market_data,
    meta: {
      requireAuth: true,
    }
  },

  
  
  // {
  //   path: "/register",
  //   name: "register",
  //   component: register,
  //   meta: {
  //     requireAuth: false,
  //   }
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (to) {
  return VueRouterPush.call(this, to).catch(err => err)
}


export default router;
router.beforeEach((to, from, next) => {
  // 判断路由访问是否需要的登录
  // requireAuth为true时，不需要登录
  if (to.matched.some(res => res.meta.requireAuth)) {
      next();
  } 
  // 不设置requireAuth为true时，需要登录
  else {   
    // 判断是否已经登录
      if (window.localStorage.getItem('access-token')) {
          next();
      } else {
        Dialog.confirm({
          message: "您还未登录，请先登录",
          confirmButtonText:"确定",
          confirmButtonColor:"#26a18d",
          cancelButtonText: "取消"
        })
          .then(() => {
            next({path: '/login',query: {
              redirect: to.fullPath
            }});
          })
          .catch(() => {
            // on cancel
          });
      }
  }
});
