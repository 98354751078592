/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-08-05 16:34:30
 * @LastEditTime: 2022-08-05 16:50:26
 * @FilePath: \web-app\src\utils\tools.js
 */

import getTel from './geTel';


export default {
    getTel
}