/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:56:52
 * @LastEditTime: 2023-05-05 00:17:21
 * @FilePath: \web-app\src\api\api.js
 */
import * as API from "./";

export default {
  // 商户详情
  getShopDetailById: (id) => {
    return API.GET(`/shop/${id}`);
  },
  // 创建订单
  createwxPay: (params) => {
    return API.GET(`/pay/createwxPay`,params);
  },

  //获取微信配置
  wxConfig: (params) => {
    return API.GET(`/weixin/getConfig`,params);
  },
  // 微信支付
  wxPay: (openId) => {
    return API.GET(`/pay/wxpayTest?openId=${openId}`);
  },
  // 注册
  register: (params) => {
    return API.POST(`/user/reg`, params);
  },
  // 微信登录
  wxLogin: (params) => {
    return API.POST(`/weixin/login`, params);
  },
  // 获取openid
  getOpenId: (code) => {
    return API.GET(`/weixin/getOpenId?code=${code}`);
  },
  // 商户优惠券
  getShopCoupon: (shopId) => {
    return API.GET(`/shop/getUsercoupon?shopId=${shopId}`);
  },

  getShopCoupon1: () => {
    return API.GET(`/shop/getUsercoupon1`);
  },

  // 获取商家活动
  getShopActive: (shopId) => {
    return API.GET(`/shop/getShopActive?shopId=${shopId}`);
  },
  // 获取活动奖励,奖项
  getActivePrize: (activeId) => {
    return API.GET(`/shop/getActivePrize?activeId=${activeId}`);
  },
  // 抽奖
  doActivePrize: (activeId) => {
    return API.GET(`/shop/doActivePrize?activeId=${activeId}`);
  },
  getVideoUrl:(shopId)=> {
    return API.GET(`/shop/getShopVideo?shopId=${shopId}`);
  },
  shareDYVideo:(shareid)=>{
    //console.log(`/dy/shareDY?videoPath=${path}&title=${title}&tag=${tag}&poi_id=${poi_id}`);
    return API.GET(`/dy/shareDY?shareid=${shareid}`);
  },

  shareDYVideo1:(params)=>{
    //console.log(`/dy/shareDY?videoPath=${path}&title=${title}&tag=${tag}&poi_id=${poi_id}`);
    return API.GET(`/dy/shareDY1`,params);
  },
  aliLogin: (params) => {
    return API.GET(`/aliyun/login`, params);
  },

  dyConfig: (url) => {
    return API.GET(`/dy/dyCoinfg?url=${url}`);
  },
  
  getShareCardId: (id) => {
    return API.GET(`/shop/receiveCoupon?couponId=${id}`);
  },
  dyLogin :(params) => {
    return API.GET(`/dy/login`,params);
  },

  aliPay: (params) => {
    return API.GET(`/aliyun/createaliPay`,params)
  },
  dyShareResult: (shareid) => {
    return API.GET(`/dy/shareDYResult?shareid=${shareid}`);
  },

  postMsg: (params) => {
    return API.GET(`/weixin/pubMessage`,params);
  },

  getViewCoupon:(shopId)=>{
    return API.GET(`/shop/getViewCoupon?shopId=${shopId}`);
  },
  
};

