/*
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:56:52
 * @LastEditTime: 2023-05-05 00:17:21
 * @FilePath: \web-app\src\api\api.js
 */
import * as API_SHOP from "./";

export default {
  // 新增商户
  addShop: (params) => {
    return API_SHOP.POST(`/qmdr/prepare`, params);
  },
  // 上传
  upload: (params) => {
    return API_SHOP.POST(`/file/ossUpload`, params,"https://pxkj.api.ld-top.com/");
  },
  // 地区
  addressTree: (params) => {
    return API_SHOP.GET(`/xlxy/area/tree`, params);
  },
  // 登录
  login: (params) => {
    return API_SHOP.POST(`/login`, params,"https://pxkj.api.ld-top.com/");
  },

  getCodeImg:() => {
    //alert("1222");
    return API_SHOP.GET(`/captchaImage`,null,"https://pxkj.api.ld-top.com/");
  },
  // 获取商户
  getUserInfo:()=>{
    return API_SHOP.GET('/getInfo',null,"https://pxkj.api.ld-top.com/")
  },
  getNoteDetail:(id)=>{
    return API_SHOP.GET(`/system/notice/${id}`,null,"https://pxkj.api.ld-top.com/");
  },
  
  getNoticeList:()=>{
    return API_SHOP.GET('qmdr/shop/listByUser',null,"https://pxkj.api.ld-top.com/")
  },
  updatePwd:(params)=>{
    return API_SHOP.PUT('/system/user/profile/updatePwd?'+params,null,"https://pxkj.api.ld-top.com/")
  },

  getTotal:()=>{
    return API_SHOP.GET('/qmdr/shop/getTotal',null,"https://pxkj.api.ld-top.com/")
  },

  getOrderByShop:()=>{
    return API_SHOP.GET('/qmdr/orderDetails/list?pageNum=1&pageSize=1000000',null,"https://pxkj.api.ld-top.com/")
  },


  getActiveList:()=>{
    return API_SHOP.GET('/qmdr/shopActive/list?pageNum=1&pageSize=1000000',null,"https://pxkj.api.ld-top.com/")
  },


  getFileList:()=>{
    return API_SHOP.GET('/qmdr/fileInfo/list?pageNum=1&pageSize=1000000',null,"https://pxkj.api.ld-top.com/")
  },

  getCouponList:()=>{
    return API_SHOP.GET('/qmdr/card/list?pageNum=1&pageSize=1000000',null,"https://pxkj.api.ld-top.com/")
  },
  
  getPrizeList:(id)=>{
    return API_SHOP.GET('/qmdr/prize/list?activeId='+id+'&pageNum=1&pageSize=1000000',null,"https://pxkj.api.ld-top.com/")
  },


  addShopActive: (params) => {
    return API_SHOP.POST(`/qmdr/shopActive`, params,"https://pxkj.api.ld-top.com/");
  },


  UpdateShopActive: (params) => {
    return API_SHOP.PUT(`/qmdr/shopActive`, params,"https://pxkj.api.ld-top.com/");
  },


  addShopFile: (params) => {
    return API_SHOP.POST(`/qmdr/fileInfo`, params,"https://pxkj.api.ld-top.com/");
  },


  UpdateShopFile: (params) => {
    return API_SHOP.PUT(`/qmdr/fileInfo`, params,"https://pxkj.api.ld-top.com/");
  },

  addShopCoupon: (params) => {
    return API_SHOP.POST(`/qmdr/card`, params,"https://pxkj.api.ld-top.com/");
  },


  addShopPrize: (params) => {
    return API_SHOP.POST(`/qmdr/prize`, params,"https://pxkj.api.ld-top.com/");
  },

  UpdateShopCoupon: (params) => {
    return API_SHOP.PUT(`/qmdr/card`, params,"https://pxkj.api.ld-top.com/");
  },


  validaCoupon: (params) => {
    return API_SHOP.GET(`/qmdr/shop/validaCoupon?shopId=`+params.shopId+"&couponId="+params.couponId,null,"https://pxkj.api.ld-top.com/");
  },


  getShopActive:(id)=>{
    return API_SHOP.GET('/qmdr/shopActive/'+id,null,"https://pxkj.api.ld-top.com/")
  },


  getCardListByActiveId:(id)=>{
    return API_SHOP.GET('/qmdr/prize/getCardListByActiveId?id='+id,null,"https://pxkj.api.ld-top.com/")
  },

  getMarketData:()=>{
    return API_SHOP.GET('/qmdr/shop/getMarketData',null,"https://pxkj.api.ld-top.com/")
  },
  updateStatus:(params)=>{
    return API_SHOP.GET('/qmdr/card/updateStatus?id='+ params.id +'&state='+ params.state +'',null,"https://pxkj.api.ld-top.com/")
  },

  delActive:(id)=>{
    return API_SHOP.DELETE('/qmdr/shopActive/'+ id +'',null,"https://pxkj.api.ld-top.com/")
  },
  delFile:(id)=>{
    return API_SHOP.DELETE('/qmdr/fileInfo/'+ id +'',null,"https://pxkj.api.ld-top.com/")
  },

  delCard:(id)=>{
    return API_SHOP.DELETE('/qmdr/card/'+ id +'',null,"https://pxkj.api.ld-top.com/")
  },
  
  
};

