<template>
    <div class="transactionAmount">
      <div class="hear">
        <h4>活动奖项管理</h4>
      </div>
      <div class="list-box">
        <van-form @submit="onSubmit">
          <van-field
            v-model="params.prizeName"
            name="奖项名称"
            label="奖项名称"
            placeholder="奖项名称"
            :rules="[{ required: true, message: '请填写奖项名称' }]"
          />
          <van-field
            v-model="params.prizeNum"
            name="奖项数量"
            label="奖项数量"
            placeholder="奖项数量"
            :rules="[{ required: true, message: '请填写奖项数量' }]"
          />
          <van-field
            v-model="CType"
            name="奖品"
            label="奖品"
            placeholder="奖品"
            :rules="[{ required: true, message: '请填写奖品' }]" @click="showType"
          />
          <van-popup
            v-model="isShowType"
            position="bottom"
            style="height: 50%"
            round
          >
          <van-picker
            title="奖品"
            :columns="columns"
            @confirm="onConfirm"
            @cancel="onCancel"
            @change="onChange" :show-toolbar="true"
           />
          </van-popup>
          
          <input type="hidden"  v-model="params.shopId" name="shopId">
          <input type="hidden"  v-model="params.activeId" name="activeId">
          
          <div style="margin: 16px">
            <van-button round block type="info" native-type="submit"
              >提交</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="home" @click="goHome">
        <img src="@/assets/images/return.png" />
      </div>
    </div>
  </template>
    
    <script>
  import API from "@/api/api_shop.js";
  import { DatetimePicker } from "vant";
  import { Popup } from "vant";
  
  export default {
    name: "transactionAmount",
    data() {
      return {
        columns:[],
        id:0,
        CType:"",
        isShowType:false,
        activrePic: [], // 活动图片
        isShowBegin: false,
        isShowEnd: false,
        minDate: new Date(),
        maxDate: new Date(2088, 10, 1),
        currentDate: new Date(),
        cascaderValue: "",
        addressShow: false,
        fieldNames: {
          text: "label",
          value: "value",
          children: "children",
        },
        shopPic: [], // 店铺照片
        shopBusinesPapare: [], // 店铺营业执照
        cardFrontPic: [], // 身份证正面
        cardBackPic: [], // 身份证正面
        headPic: [], // 门头照
        empowerPic: [], //授权函照片
        params: {
          prizeName: "",
          prizeNum: "", // 失效时间
          couponId: 0,
          shopId:0,
          activeId:0
        },
      };
    },
    created() {
      this.id = this.$route.query.id;
    //   if(this.id){
    //       this.getShopActive(this.id);
    //   }

      this.params.activeId=this.id;
      this.params.shopId=localStorage.getItem("shopId");
      this.getCardListByActiveId()
    },
    methods: {
      showType(){
        this.isShowType=true;
      },
      getCardListByActiveId(){
        API.getCardListByActiveId(this.id).then((res) => {
          if (res.code == 200) {
            var result=res.data;
            for (var i = 0; i < result.length; i++) {
                let obj={
                    'text':result[i].couponName,
                    'value':result[i].id,
                }
                this.columns.push(obj);
            }
          }
        });
      },

      onConfirm(selectedValues){ 
        //console.log(selectedValues);
        this.CType=selectedValues.text;
        this.params.couponId=selectedValues.value;
        this.isShowType=false;
        //showToast(`当前值: ${selectedValues.join(',')}`);
      },
      onChange(selectedValues){
        //showToast(`当前值: ${selectedValues.join(',')}`);
      },
     onCancel(){
        this.isShowType=false;
        //showToast('取消');
     },
      onReadFile(file, data) {
        var formData = new FormData();
        formData.append("file", file.file);
        API.upload(formData).then((res) => {
          if (res.code == 200) {
            this.params[data.name] = res.fileName;
          }
        });
      },
  
      getShopActive(id){
          API.getShopActive(id).then((res) => {
          if (res.code == 200) {
            this.params=res.data;
          }
        });
      },
      showEndDate() {
        //alert("1111");
        this.isShowEnd = true;
      },
      confirmFn1() {
        const dateStr = this.filterTime(this.currentDate, "date");
        console.log(dateStr);
        this.params.activeEndTime = dateStr;
        this.isShowEnd = false;
      },
      showBeginDate() {
        //alert("1111");
        this.isShowBegin = true;
      },
      confirmFn() {
        const dateStr = this.filterTime(this.currentDate, "date");
        console.log(dateStr);
        this.params.activeBeginTime = dateStr;
        this.isShowBegin = false;
      },
      onSubmit(values) {
        //this.params.activrePic=this.shopPic;
        //console.log(this.params);
        //return;
        API.addShopPrize(this.params).then((res) => {
          if (res.code == 200) {
            alert(`提交成功`);
            location.href = "/shopCenter/active";
          }
        });
        
          
        
        
      },
      filterTime(time, type) {
        let str = "";
        let date = new Date(time);
        let y = date.getFullYear();
        let m = date.getMonth() + 1;
        m = m < 10 ? "0" + m : m;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let minute = date.getMinutes();
        minute = minute < 10 ? "0" + minute : minute;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        if (type == "time") str = `${y}-${m}-${d}  ${h}:${minute}:${s}`;
        if (type == "date") str = `${y}-${m}-${d}`;
        return str;
      },
      goHome() {
        location.href = "/shopCenter/index";
      },
      goDetails(id) {
        location.href = "/shopCenter/prize?id=" + id;
      },
      getActiveList() {
        API.getActiveList().then((res) => {
          if (res.code == 200) {
            this.userList = res.rows;
          }
        });
      },
  
      createQrcode() {
        API.createQrcode().then((res) => {
          if (res.code == 200) {
            //location.href="/team/qrcode?img="
  
            let img =
              "https://pxkj-1322384497.cos.ap-guangzhou.myqcloud.com" + res.data;
            let code = res.userNo;
  
            location.href = "/team/qrcode?id=" + code + "&img=" + img;
          }
        });
      },
      Add(id) {
        if (id) {
          location.href = "/shopCenter/active/add?id=" + id;
        } else {
          location.href = "/shopCenter/active/add";
        }
      },
      tabClick(i) {
        this.current = i;
        //alert(this.current)
        if (this.current == 1) {
          this.m0 = 313832;
          this.shopList[0].deal = 289140;
          this.shopList[0].income = 578.28;
  
          this.shopList[1].deal = 24692;
          this.shopList[1].income = 49.38;
        }
        if (this.current == 2) {
          this.m0 = 623004;
  
          this.shopList[0].deal = 510003;
          this.shopList[0].income = 1020;
  
          this.shopList[1].deal = 113001;
          this.shopList[1].income = 226;
        }
      },
  
      onLoad() {},
    },
  };
  </script>
    
    <style  lang="scss" scoped>
  .home {
    position: fixed;
    top: 3%;
    left: 5%;
    img {
      width: 20%;
      height: 20%;
    }
  }
  .btn {
    position: fixed;
    bottom: 20pt;
    width: 93%;
    text-align: center;
    font-size: 14pt;
    background-color: #ffcb51;
    height: 40pt;
    padding-top: 15pt;
  }
  ul {
    float: left;
    width: 100%;
    height: 50pt;
    background-color: #fff;
    margin-bottom: 20pt;
  }
  ul li {
    float: left;
    width: 100%;
    text-align: center;
    font-size: 14pt;
    margin-top: 12pt;
    height: 30pt;
    padding-top: 10pt;
  }
  .atv {
    background-color: #ffcb51;
    color: rgb(19, 18, 18);
  }
  
  .transactionAmount {
    background: #fff;
    .hear {
      width: 100%;
      height: 100px;
      background-color: #ffcb51;
      background-image: linear-gradient(62deg, #ffcb51 0%, #f7d568 100%);
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      padding: 30px 0px;
      h4 {
        font-size: 32px;
      }
      .tab {
        .item {
          font-size: 24px;
          padding: 6px 10px;
          box-sizing: border-box;
          color: #666;
          background: #fff;
          margin-right: 16px;
          border-radius: 8px;
          &.active {
            color: #ff6619;
          }
        }
      }
      .money {
        text-align: center;
        .icon {
          font-size: 30px;
          color: #666;
        }
        .num {
          font-size: 50px;
          color: #000;
        }
        p {
          font-size: 24px;
          color: #000;
        }
      }
    }
    .list-box {
      padding: 20px;
      box-sizing: border-box;
      h4 {
        font-size: 36px;
        text-align: center;
        margin-bottom: 30px;
      }
      .lists {
        width: 100%;
        height: auto;
        overflow-y: auto;
  
        //   display: flex;
        //   justify-content: space-between;
        //   align-content: center;
        ul {
          float: left;
          width: 100%;
          height: 60pt;
          background-color: #fff;
        }
        ul li {
          float: left;
          width: 45%;
          text-align: center;
          font-size: 12pt;
          height: 60pt;
          text-align: left;
          padding-left: 15px;
          margin-top: 0px;
          border-bottom: 1px solid #ccc;
        }
        .item-box {
          width: 100%;
          background: #eee;
          border-radius: 10px;
          padding: 20px;
          margin-bottom: 20px;
          box-sizing: border-box;
          .name {
            font-size: 28px;
            color: #000;
            font-weight: 700;
            margin-bottom: 10px;
          }
          .item {
            width: 100%;
            font-size: 26px;
            display: flex;
            justify-content: space-between;
            align-content: center;
            .red {
              color: red;
            }
            .fontw700 {
              font-weight: 700;
            }
            p {
              margin-bottom: 10px;
            }
          }
        }
      }
    }
  }
  </style>