<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-07-18 14:18:02
 * @LastEditTime: 2022-09-02 22:25:25
 * @FilePath: \web-app\src\views\login.vue
-->
<template>
    <div class="login">
      <div class="hear">
        <h2>欢迎使用我买单</h2>
        <p class="tip">我买单 让商家推广更简单</p>
      </div>
      <div class="formBox">
        <van-form @submit="onSubmit">
          <van-field
            v-model="loginForm.username"
            name="username"
            label="登录名"
            label-width="60px"
            placeholder="请输入登录名"
            :rules="[{ required: true, message: '请输入手机号' }]"
          />
          <van-field
            v-model="loginForm.password"
            type="password"
            name="password"
            label="密码"
            label-width="60px"
            placeholder="请输入密码"
            :rules="[{ required: true, message: '请输入密码' }]"
          />
          <!-- <van-field
            v-model="loginForm.code"
            name="code"
            label="验证码"
            label-width="60px"
            placeholder="请输入验证码"
            :rules="[{ required: true, message: '请输入验证码' }]"
          >
            <template #button>
              <img :src="codeUrl" @click="getCode" class="login-code-img" />
            </template>
          </van-field> -->
          <van-field
            v-show="false"
            v-model="loginForm.uuid"
            type="hidden"
            name="uuid"
          />
          <div style="margin: 16px">
            <van-button color="#333" block type="info" native-type="submit"
              >登录</van-button
            >
          </div>
        </van-form>
      </div>
      <div class="footer">
        <img class="logo" src="@/assets/images/logo.png" alt="logo" />
        <p>
          商家收款资金由国内专业持牌收单机构(微信支付、支付宝、云闪付、商业银行等)结算处理，由人民银行监管确保资金安全
        </p>
        <p>
          目前我买单全量支持商家入驻，更有限时费率优惠支持，如有合作意向请联系市场销售，我们会第一时间与你联系
        </p>
        <p>
          更多介绍请登录 http://www.womaidan.com 官网了解或联系市场销售进行营销支付演示
        </p>
      </div>
    </div>
  </template>
  <script>
  import API from "@/api/api_shop.js";
  export default {
    data() {
      return {
        loginForm: {
          username: "",
          password: "",
          rememberMe: false,
          code: "",
          uuid: "",
        },
        codeUrl: "",
        //验证码开关
        captchaOnOff: true,
        // 注册开关
        register: false,
        userType:'',
        wxCode:""
      };
    },
    created() {
      //alert(this.wxCode);
      this.wxCode = this.$route.query.code;
      localStorage.clear();
      //alert(this.wxCode);
     
      if (!this.wxCode) {
        this.getWXcode();
      } else {
        //this.getCode();
        //this.getUserInfo();
        //this.wxLogin();
      }
    },
    methods: {
      getWXcode() {
        //alert("111");
        let redirectUri = encodeURIComponent(
          // `http://192.168.1.3:5001/payment?shopId=${this.shopId}`
  
          `https://h5.womaidan.com/shopCenter/login`
        );
        //去微信公众号官方授权页根据appid获取code，之后会携带code值自行返回redirect_uri页面
        window.location.href =
          "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxc04364be5867ff13&redirect_uri=" +
          redirectUri +
          "&response_type=code&scope=snsapi_base#wechat_redirect";
      },
      getUserInfo(){
        API.getUserInfo().then((res) => {
          if (res.code==200){
            console.log(res);
            // this.userType=res.userType;
            // localStorage.setItem("userType",this.userType)
            // let url_param=this.$route.query.reurl;
            // if(typeof url_param === 'undefined'){
            //   location.href="/index";
            // }else{
            //   location.href=url_param;
            // }
          }
        })
      },
      onSubmit(values) {
        //let j= JSON.parse("{\"username\":\""+values.username+"\",\"password\":\""+ values.password +"\",\"code\":\""+values.code+"\",\"uuid\":\""+ values.uuid +"\",\"wxcode\":\""+this.wxCode+"\"}");
        //console.log(j);
        
        let params = {
        username: values.username,
        password: values.password,
        code: values.code,
        uuid: values.uuid,
        wxcode: this.wxCode,
      };
        API.login(params).then((res) => {
          if (res.code == 200) {
            localStorage.setItem("access-token-shop", res.token);
            //this.getUserInfo();
            
            this.$router.push({
              path: "/shopCenter/index",
            });
            
            
          }
        });
      },
      getCode() {
        // let url_param=this.$route.query.reurl;
        // if(localStorage.getItem("access-token")!=null && url_param){
        //   location.href='/'+url_param;
        // }
        //alert("1232132");
        API.getCodeImg().then((res) => {
          //alert(res);
          this.captchaOnOff =
            res.captchaOnOff === undefined ? true : res.captchaOnOff;
          if (this.captchaOnOff) {
            this.codeUrl = "data:image/gif;base64," + res.img;
            this.loginForm.uuid = res.uuid;
          }
        });
      },
    },
  };
  
  </script>
  <style lang="scss" scoped>
  .login {
    width: 100%;
    height: 100vh;
    background-color: #ffcb51;
    background-image: linear-gradient(62deg, #ffcb51 0%, #ffcb51 100%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .hear {
      text-align: center;
      h2 {
        font-size: 60px;
        color: #000;
        font-weight: 700;
        letter-spacing: 6px;
        margin-bottom: 10px;
      }
      .tip {
        font-size: 26px;
        columns: #666;
      }
    }
    .formBox {
      ::v-deep(.van-cell) {
        background: transparent;
        .van-field__label {
          color: #000;
        }
        &::after {
          border-color: #000;
        }
        .van-field__control::-webkit-input-placeholder {
          color: #fff;
        }
      }
      .login-code-img {
        width: 120px;
        height: 48px;
        float: right;
      }
    }
    .footer {
      text-align: center;
      .logo {
        width: 100px;
        height: 100px;
        margin-bottom: 30px;
      }
      p {
        font-size: 12px;
        color: #333;
        margin-bottom: 6px;
      }
    }
  }
  </style>