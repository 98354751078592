<!--
 * @Author: YHZ
 * @Email: 
 * @Date: 2022-05-11 11:16:50
 * @LastEditTime: 2022-08-28 14:41:43
 * @FilePath: \web-app\src\App.vue
-->
<template>
  <div id="app">
      <router-view />

  </div>
</template>

<script>
export default {
  data() {
    return {
      
    }
  },
  // 使用watch 监听$router的变化,
  // watch: {
  //   '$route': function(to,from){
  //       document.body.scrollTop = 0
  //       document.documentElement.scrollTop = 0
  //   }
  // }
  watch: {
    '$i18n.locale'(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.$router.go(0)
      }
    },
  },
}
</script>

<style>
@import '@/assets/css/common.css';
#app {
  width: 100%;
  height: 100vh;
  background: #f4f4f4;
  padding-bottom: 30px;
  box-sizing: border-box;
}
</style>
