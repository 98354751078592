/*
 * @Author: error: git config user.name && git config user.email & please set dead value or install git
 * @Date: 2022-08-26 23:36:05
 * @LastEditors: error: git config user.name && git config user.email & please set dead value or install git
 * @LastEditTime: 2022-08-30 22:45:12
 * @FilePath: \web-app\src\assets\language\index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enLocale from './language-en';
import usLocal from './language-cn';

import {Locale} from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import zhCN from 'vant/lib/locale/lang/zh-CN'




Vue.use(VueI18n);
const messages = {
    en_US: {
        ...enLocale
    },
    zh_CN: {
        ...usLocal
    }
}
const i18n = new VueI18n({
    locale: localStorage.getItem('lang') || 'zh_CN', // 通过this.$i18n.locale的值实现语言切换
    messages,
});


function vantLocales (lang) {
    if (lang === 'en_US') {
      Locale.use(lang, enUS)
    } else if (lang === 'zh_CN') {
      Locale.use(lang, zhCN)
    }
}
 


export {i18n, vantLocales}
// export default i18n;
