var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"transactionAmount"},[_vm._m(0),_c('div',{staticClass:"list-box"},[_c('div',{staticClass:"lists"},[_c('div',{staticClass:"item-box"},[_c('div',{staticClass:"item"},[_c('ul',[_c('li'),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_c('li',[_vm._v("播放量")]),_c('li',[_vm._v(_vm._s(_vm.viewNum))]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("点赞量")]),_c('li',[_vm._v(_vm._s(_vm.likeNum))]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("转发量")]),_c('li',[_vm._v(_vm._s(_vm.commentNum))]),_c('li',[_vm._v(_vm._s(_vm.totalShare))]),_c('li'),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")]),_c('li',[_vm._v("---")])])])])])]),_c('div',{staticClass:"home",on:{"click":_vm.goHome}},[_c('img',{attrs:{"src":require("@/assets/images/return.png")}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hear"},[_c('h4',[_vm._v("营销数据")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/images/dy0.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/images/wx0.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/images/ks.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/images/xhs.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('img',{attrs:{"src":require("@/assets/images/sph.png")}})])
}]

export { render, staticRenderFns }